/* eslint-disable no-nested-ternary */
import React from 'react';
import { Button, Grid, Skeleton, Icon } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import classNames from 'classnames';
import { DownloadButton } from '../../componentsV2/common';
import routes from '../variables/routes';
import { isValidHttpUrl, createAPIRelativeUrl } from './urlManipulation';
import { isOdd } from './numberManipulation';
import { sourceTextFromData } from './objectManipulation';
import { domain, dutchTemplate, templateStorage } from '../variables';
import Cookies from '../../cookies/cookies';

export const getActionButtons = ({
  product,
  doFeatureToggle,
  isInvest,
  isPanel,
  classes,
  buttonEachRow,
  onButtonClick,
  loading,
  variables: initialVariables,
  externalParams,
  isAgent,
  fitButtonsToWidth,
  capitalizeText,
  isOnFloatBar,
}) => {
  const domains = {
    ...domain,
    ...(initialVariables?.domains || {}),
  };
  const variables = {
    ...initialVariables,
    domains,
    product,
  };
  const templateName = Cookies.getCookie(templateStorage);

  const brochures =
    (isPanel &&
      product?.brochures &&
      product?.brochures?.length > 0 &&
      product?.brochures?.map(brochure => ({
        active: !!brochure,
        className: isOnFloatBar ? 'primaryButtonInverted' : 'secondaryButton',
        download: brochure,
      }))) ||
    [];

  let calculatorUrl =
    (isAgent && product?.calculatorWizard?.agentSlug) ||
    product?.calculatorWizard?.slug ||
    '';
  if (calculatorUrl) {
    calculatorUrl = sourceTextFromData(variables, calculatorUrl);
    if (isValidHttpUrl(calculatorUrl)) {
      calculatorUrl = createAPIRelativeUrl({
        path: calculatorUrl,
        queryObject: externalParams,
      });
    } else {
      calculatorUrl = `${routes.wizards.index}/${calculatorUrl || ''}`;
    }
  }

  let quoteUrl =
    (isAgent && product?.quoteWizard?.agentSlug) || product?.quoteWizard?.slug || '';
  if (quoteUrl) {
    quoteUrl = sourceTextFromData(variables, quoteUrl);
    if (isValidHttpUrl(quoteUrl)) {
      quoteUrl = createAPIRelativeUrl({ path: quoteUrl, queryObject: externalParams });
    } else {
      quoteUrl = `${routes.wizards.index}/${quoteUrl || ''}`;
    }
  }

  let contactUrl =
    (isAgent && product?.contactWizard?.agentSlug) || product?.contactWizard?.slug || '';
  if (contactUrl) {
    contactUrl = sourceTextFromData(variables, contactUrl);
    if (isValidHttpUrl(contactUrl)) {
      contactUrl = createAPIRelativeUrl({
        path: contactUrl,
        queryObject: externalParams,
      });
    } else {
      contactUrl = `${routes.products.contactUs}?title=${product?.title ||
        ''}&slug=${product?.slug || ''}`;
    }
  }

  let buyOnlineUrl =
    (isAgent && product?.buyOnlineWizard?.agentSlug) ||
    product?.buyOnlineWizard?.slug ||
    '';
  if (buyOnlineUrl) {
    buyOnlineUrl = sourceTextFromData(variables, buyOnlineUrl);

    if (isValidHttpUrl(buyOnlineUrl)) {
      buyOnlineUrl = createAPIRelativeUrl({
        path: buyOnlineUrl,
        queryObject: externalParams,
      });
    } else {
      buyOnlineUrl = `${routes.wizards.index}/${buyOnlineUrl || ''}`;
    }
  }

  let learnMoreUrl = product?.additionalSettings?.externalLink || product?.slug || '';
  if (learnMoreUrl) {
    learnMoreUrl = sourceTextFromData(variables, learnMoreUrl);
    if (isValidHttpUrl(learnMoreUrl)) {
      learnMoreUrl = createAPIRelativeUrl({
        path: learnMoreUrl,
        queryObject: externalParams,
      });
    }
    learnMoreUrl = `${routes.products.index}/${learnMoreUrl || ''}`;
  }
  const buttons = [
    isPanel && {
      active: !!product?.brochure,
      className: isOnFloatBar ? 'primaryButtonInverted' : 'secondaryButton',
      download: product?.brochure,
      fixedText: true,
    },
    ...brochures,
    {
      active: !!calculatorUrl,
      className: isOnFloatBar ? 'primaryButtonInverted' : 'secondaryButton',
      url: calculatorUrl,
      title: 'Calculator',
    },
    {
      active: !!quoteUrl,
      className: isPanel
        ? isOnFloatBar
          ? 'primaryButtonInverted'
          : 'primaryButton'
        : 'secondaryButton',
      url: quoteUrl,
      title: isInvest ? 'Get Advice' : 'Get Quote',
    },
    {
      active: !!contactUrl,
      className: isPanel
        ? isOnFloatBar
          ? 'primaryButtonInverted'
          : 'primaryButton'
        : 'secondaryButton',
      url: contactUrl,
      title: 'Contact Us',
    },
    {
      active: !!buyOnlineUrl,
      className: 'importantButton',
      url: buyOnlineUrl,
      title: doFeatureToggle('useFatumText') ? 'Calculate & Purchase' : 'Purchase Now',
    },
    !isPanel && {
      active: !!learnMoreUrl,
      className: isOnFloatBar ? 'invertContained' : 'primaryButton',
      url: learnMoreUrl,
      title: 'Learn More',
    },
  ].filter(x => x && x.active);

  return (
    buttons &&
    buttons?.length > 0 &&
    buttons?.map((button, index) => {
      let finalButtonEachRow = buttonEachRow;
      if (fitButtonsToWidth && index === buttons?.length - 1 && isOdd(buttons?.length)) {
        finalButtonEachRow = 1;
      }

      return (
        <Grid
          item
          xs={12}
          lg={12 / finalButtonEachRow}
          align="center"
          className={classes.zGrid}
        >
          {loading ? (
            <Skeleton
              variant="text"
              animation="wave"
              height={50}
              width="80%"
              className={classes.button}
            />
          ) : button?.download ? (
            <DownloadButton
              buttonVariant="outlined"
              className={classes.secondaryButton}
              download={button?.download}
              sx={{
                height: '100%',
              }}
              defaultDownloadText="Brochure"
              fixedText={button?.fixedText}
            />
          ) : (
            <Button
              justifyContent="center"
              className={classNames({
                'calc_purchase_dutch_tour': button?.title === 'Calculate & Purchase'
              }, classes[button?.className])}
              onClick={onButtonClick}
              component={
                button?.onButtonClickRedirect
                  ? 'div'
                  : isValidHttpUrl(button?.url)
                    ? 'a'
                    : RouterLink
              }
              href={isValidHttpUrl(button?.url) && button?.url}
              target={isValidHttpUrl(button?.url) ? '_blank' : '_self'}
              to={!isValidHttpUrl(button?.url) && button?.url}
              endIcon={isOnFloatBar && <Icon data-nosnippet>chevron_right</Icon>}
              sx={{ height: '100%' }}
            >
              {capitalizeText ? (
                <span>{button?.title?.toUpperCase()}</span>
              ) : (
                <span>{button?.title}</span>
              )}
            </Button>
          )}
        </Grid>
      );
    })
  );
};

export const getTemplateSettings = ({ template = {}, isSmall }) => {
  let showImage = false;
  let imageGridSize = 12;
  let contentGridSize = 12;
  let cardButtonEachRow = 1;
  let cardEachRow = 3;

  if (!isSmall) {
    if (template?.size === 'small') {
      imageGridSize = 12;
      contentGridSize = 12;
      cardButtonEachRow = 1;
      cardEachRow = 3;
    }
    if (template?.size === 'medium') {
      imageGridSize = 12;
      contentGridSize = 12;
      cardButtonEachRow = 3;
      cardEachRow = 2;
    }
    if (template?.size === 'large') {
      imageGridSize = 4;
      contentGridSize = 8;
      cardButtonEachRow = 3;
      cardEachRow = 1;
    }
  }

  if (template?.showImage) {
    showImage = true;
  }

  return {
    imageGridSize,
    contentGridSize,
    cardButtonEachRow,
    showImage,
    cardEachRow,
  };
};
