import { parseBoolWizards } from './boolManipulation';
import { isValidHttpUrl } from './urlManipulation';
import {
  domain,
  consentKey,
  calcOperators,
  titleDropdownOptions,
  dutchCountries,
  agentType,
  customerType,
} from '../variables';
import { flattenObjectArray, getRef } from './objectManipulation';
import { toTitleCase } from './stringManipulation';
import routes from '../variables/routes';
import routerHistory from '../../history';
import { removeSessionStorage } from '../../storage/sessionStorage';
import { countriesWithAlpha2 } from './constants';

export const promiseTimeout = (ms, data) => {
  return new Promise(resolve => setTimeout(() => resolve(data), ms));
};

export function postIframe(postObj) {
  if (typeof window !== 'undefined') {
    window.parent.postMessage(postObj, '*');
    // eslint-disable-next-line no-undef
    // const myEvent = new CustomEvent('message', postObj); // my_iframe_event
    // window.parent.dispatchEvent(myEvent, '*');
    // console.log('Sync Data sent to iframe');
  }
}

export const formatImageUrlFromStrapi = _cover => {
  const cover = _cover;
  // // console.log('url: ', cover);
  if (isValidHttpUrl(cover?.url)) return cover;
  if (cover?.provider !== 'local') return cover;
  cover.url = `${domain.strapi}${cover?.url}`;
  // // console.log('newUrl: ', cover?.url);
  return cover;
};

export const translateFromStrapi = (links, key, locale) => {
  if (!links || links.length === 0) return null;
  const newLinks = links.map(_x => {
    const x = { ..._x };
    const newValue = x[`${key}_${locale}`] || x[key];
    x[key] = newValue;
    return x;
  });

  return newLinks;
};

export const transformConditionalValue = (_conditionalValue, fieldType) => {
  let conditionalValue = _conditionalValue;
  if (fieldType === 'string') {
    conditionalValue = conditionalValue?.toString() || '';
  } else if (fieldType === 'boolean') {
    conditionalValue = parseBoolWizards(conditionalValue, fieldType);
  } else if (fieldType === 'number') {
    conditionalValue = parseFloat(conditionalValue) || 0;
    // console.log('conditionalValue: ', conditionalValue);
  }
  return conditionalValue;
};

export const getTransformPeriod = _input => {
  let input = _input;
  if (input === 'Monthly') {
    input = 'M';
  } else if (input === 'Quarterly') {
    input = 'Q';
  } else if (input === 'SemiAnnual') {
    input = 'SA';
  } else {
    input = 'A';
  }

  return input;
};

export const transformConditionalStatus = (
  conditionalValue,
  operator,
  _storeConditionalKeyValue,
  fieldType,
  arrayKey,
) => {
  let bool = true;

  if (operator === 'equal') {
    const storeConditionalKey = transformConditionalValue(
      _storeConditionalKeyValue,
      fieldType,
    );
    // console.log('storeConditionalKey: ', storeConditionalKey);
    bool = conditionalValue === storeConditionalKey;
  } else if (operator === 'notEqual') {
    const storeConditionalKey = transformConditionalValue(
      _storeConditionalKeyValue,
      fieldType,
    );
    // console.table({ storeConditionalKey, conditionalValue });
    bool = conditionalValue !== storeConditionalKey;
  } else if (operator === 'notEqualAndValid') {
    const storeConditionalKey = transformConditionalValue(
      _storeConditionalKeyValue,
      fieldType,
    );
    // // console.log('storeConditionalKey: ', storeConditionalKey);
    // // console.log('conditionalValue: ', conditionalValue);
    bool =
      // storeConditionalKey &&
      conditionalValue &&
      // storeConditionalKey.toString().length !== 0 &&
      conditionalValue.toString().length !== 0 &&
      conditionalValue !== storeConditionalKey;
  } else if (operator === 'notEqualOrNotValid') {
    const storeConditionalKey = transformConditionalValue(
      _storeConditionalKeyValue,
      fieldType,
    );
    bool =
      //! storeConditionalKey ||
      !conditionalValue ||
      // storeConditionalKey.toString().length === 0 ||
      conditionalValue.toString().length === 0 ||
      conditionalValue !== storeConditionalKey;
  } else if (operator === 'greaterThan') {
    const storeConditionalKey = transformConditionalValue(
      _storeConditionalKeyValue,
      fieldType,
    );
    bool = storeConditionalKey > conditionalValue;
  } else if (operator === 'greaterThanAndEqualTo') {
    const storeConditionalKey = transformConditionalValue(
      _storeConditionalKeyValue,
      fieldType,
    );
    // console.log(storeConditionalKey);
    // console.log(conditionalValue);
    bool = !!conditionalValue && storeConditionalKey >= conditionalValue;
  } else if (operator === 'lessThanAndEqualTo') {
    const storeConditionalKey = transformConditionalValue(
      _storeConditionalKeyValue,
      fieldType,
    );
    bool = storeConditionalKey <= conditionalValue;
  } else if (operator === 'lessThan') {
    const storeConditionalKey = transformConditionalValue(
      _storeConditionalKeyValue,
      fieldType,
    );
    bool = storeConditionalKey < conditionalValue;
  } else if (operator === 'valid') {
    const storeConditionalKey = transformConditionalValue(
      _storeConditionalKeyValue,
      fieldType,
    );
    bool = !!storeConditionalKey;
  } else if (operator === 'notValid') {
    const storeConditionalKey = transformConditionalValue(
      _storeConditionalKeyValue,
      fieldType,
    );
    bool = !storeConditionalKey;
  } else if (operator === 'includedInArray') {
    const tempValue = _storeConditionalKeyValue?.find(
      x => transformConditionalValue(x[arrayKey], fieldType) === conditionalValue,
    );
    // const storeConditionalKey = transformConditionalValue(_storeConditionalKeyValue, fieldType);
    bool = !!tempValue;
  } else if (operator === 'includes') {
    const storeConditionalKey = transformConditionalValue(
      _storeConditionalKeyValue,
      fieldType,
    );
    // const storeConditionalKey = transf  ormConditionalValue(_storeConditionalKeyValue, fieldType);
    bool = storeConditionalKey?.includes(conditionalValue);
  } else if (operator === 'notIncludes') {
    const storeConditionalKey = transformConditionalValue(
      _storeConditionalKeyValue,
      fieldType,
    );
    // const storeConditionalKey = transformConditionalValue(_storeConditionalKeyValue, fieldType);
    bool = !storeConditionalKey?.includes(conditionalValue) && !!storeConditionalKey;
  } else {
    const storeConditionalKey = transformConditionalValue(
      _storeConditionalKeyValue,
      fieldType,
    );
    bool = conditionalValue === storeConditionalKey;
  }

  return bool;
};

export const generateSearchWords = object => {
  const initialArray = [];
  const flattenArray = flattenObjectArray(object, initialArray);
  // // console.log('flattenArray: ', flattenArray);
  // const myArray = flattenArray.map((str, index) => ({ value: str, id: index + 1 }));
  return flattenArray;
};

export const getLocalStorageSize = () => {
  let total = 0;
  for (const x in localStorage) {
    // Value is multiplied by 2 due to data being stored in `utf-16` format, which requires twice the space.
    const amount = localStorage[x].length * 2; /// 1024 / 1024
    if (!isNaN(amount) && localStorage.hasOwnProperty(x)) {
      // // console.log(x, localStorage.getItem(x), amount);
      total += amount;
    }
  }
  return total.toFixed(2);
};

export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
};
export const storageEstimateWrapper = () => {
  if ('storage' in navigator && 'estimate' in navigator.storage) {
    // We've got the real thing! Return its response.
    return navigator.storage.estimate();
  }

  if (
    'webkitTemporaryStorage' in navigator &&
    'queryUsageAndQuota' in navigator.webkitTemporaryStorage
  ) {
    // Return a promise-based wrapper that will follow the expected interface.
    return new Promise((resolve, reject) => {
      navigator.webkitTemporaryStorage.queryUsageAndQuota((usage, quota) => {
        resolve({ usage, quota });
      }, reject);
    });
  }

  // If we can't estimate the values, return a Promise that resolves with NaN.
  return Promise.resolve({ usage: NaN, quota: NaN });
};

export const jsonFileSize = obj => {
  // returns object size in bytes
  return encodeURI(JSON.stringify(obj)).split(/%..|./).length - 1;
};

// Get duration (in s) before (cache) expiration from headers of a fetch
// request.
export const getExpiresFromHeaders = headers => {
  // Try to use the Cache-Control header (and max-age)
  if (headers.get('cache-control')) {
    const maxAge = headers.get('cache-control').match(/max-age=(\d+)/);
    return parseInt(maxAge ? maxAge[1] : 0, 10);
  }

  // Otherwise try to get expiration duration from the Expires header
  if (headers.get('expires')) {
    return (
      parseInt(new Date(headers.get('expires')).getTime() / 1000, 10) -
      new Date().getTime()
    );
  }
  return null;
};

export const cacheAssets = assets => {
  return new Promise(function(resolve, reject) {
    // open cache
    caches
      .open('assets')
      .then(cache => {
        // the API does all the magic for us
        cache
          .addAll(assets)
          .then(() => {
            // console.log('all assets added to cache');
            resolve();
          })
          .catch(err => {
            // console.log('error when syncing assets', err);
            reject();
          });
      })
      .catch(err => {
        // console.log('error when opening cache', err);
        reject();
      });
  });
};

export const isEmail = username => {
  return /\S+@\S+\.\S+/.test(username);
};

export const formatUsername = userName => {
  if (isEmail(userName)) return userName;
  let result = userName;
  // clean mobile number
  const mobileNum = userName?.replace(/[^0-9]/g, '');

  if (mobileNum?.length === 11) {
    const mobileSearchParam = mobileNum?.substring(1, 11);
    result = mobileSearchParam;
  } else if (mobileNum?.length === 12) {
    const mobileSearchParam = mobileNum?.substring(1, 12);
    result = mobileSearchParam; // returns null if nothing found.
  } else if (mobileNum?.length === 10) {
    result = mobileNum;
  } else if (mobileNum?.length === 7) {
    const numberSearch = mobileNum;
    result = numberSearch?.length === 1 ? numberSearch[0] : null; // if more than one number comes up, return null
  }

  return result;
};

/*
// all urls will be added to cache
var assets = []; // list of urls to be cached

// cache responses of provided urls
cacheAssets(assets)
  .then(() => {
      // console.log('All assets cached')
  });
  */

export const rangeOfYears = (start, end) =>
  Array(end - start + 1)
    .fill(start)
    .map((year, index) => year + index);

export const getYearsFromRange = settings => {
  const { pastYears = 0, futureYears = 0 } = settings || {};
  // console.log('futureYears: ', futureYears);
  // console.log('pastYears: ', pastYears);

  const initialYears = rangeOfYears(
    new Date().getFullYear() - pastYears,
    new Date().getFullYear() + futureYears,
  );
  const newYears = initialYears
    .sort()
    .reverse()
    .map(year => ({ value: year, title: year }));
  return newYears;
};
export const syntaxHighlight = json => {
  if (typeof json !== 'string') {
    json = JSON.stringify(json, undefined, 2);
  }
  json = json
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;');
  return json.replace(
    /("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g,
    function(match) {
      let cls = 'number';
      if (/^"/.test(match)) {
        if (/:$/.test(match)) {
          cls = 'key';
        } else {
          cls = 'string';
        }
      } else if (/true|false/.test(match)) {
        cls = 'boolean';
      } else if (/null/.test(match)) {
        cls = 'null';
      }
      return `<span class="${cls}">${match}</span>`;
    },
  );
};

export const isMobileSafari = () => {
  return (
    navigator.userAgent.match(/(iPod|iPhone|iPad)/) &&
    navigator.userAgent.match(/AppleWebKit/)
  );
};

export const isIOSSafari = () => {
  return (
    /iP(ad|od|hone)/i.test(navigator.userAgent) &&
    /WebKit/i.test(navigator.userAgent) &&
    !/(CriOS|FxiOS|OPiOS|mercury)/i.test(navigator.userAgent)
  );
};

export const isIOS = () => {
  const { userAgent } = window.navigator;
  // // console.log('ua: ', userAgent);
  const isIPad = !!userAgent.match(/iPad/i);
  const isIPhone = !!userAgent.match(/iPhone/i);

  return isIPad || isIPhone;
};

export const iOSversion = () => {
  if (/iP(hone|od|ad)/.test(navigator.platform)) {
    // supports iOS 2.0 and later: <http://bit.ly/TJjs1V>
    const v = navigator.appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/);
    const versions = [parseInt(v[1], 10), parseInt(v[2], 10), parseInt(v[3] || 0, 10)];
    return versions[0] || null;
  }
  return null;
};

export const isSafari = () => {
  const ua = window.navigator.userAgent;
  const iOS = !!ua.match(/iP(ad|od|hone)/i);
  const hasSafariInUa = !!ua.match(/Safari/i);
  const noOtherBrowsersInUa = !ua.match(/Chrome|CriOS|OPiOS|mercury|FxiOS|Firefox/i);
  let result = false;
  if (iOS) {
    // detecting Safari in IOS mobile browsers
    const webkit = !!ua.match(/WebKit/i);
    result = webkit && hasSafariInUa && noOtherBrowsersInUa;
  } else if (window.safari !== undefined) {
    // detecting Safari in Desktop Browsers
    result = true;
  } else {
    // detecting Safari in other platforms
    result = hasSafariInUa && noOtherBrowsersInUa;
  }
  return result;
};

export const isFirefox = () => {
  return navigator.userAgent.toLowerCase().includes('firefox');
};

export const checkOnlineStatus = async () => {
  // For demo purpose you can use the favicon from MSN website
  // Also notice the appended date param which helps skip browser caching.
  try {
    const online = await fetch(
      `https://static-global-s-msn-com.akamaized.net/hp-neu/sc/2b/a5ea21.ico?d=${Date.now()}`,
    );
    return online.status >= 200 && online.status < 300; // either true or false
  } catch (err) {
    return false; // definitely offline
  }
};

export const mobileAndTabletCheck = () => {
  let check = false;
  (function(a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
        a,
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4),
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
};

export const removeNonWizardRoute = router => {
  const historyEntries = routerHistory; // router?.history;
  // console.log('historyEntries: ', historyEntries);
  if (historyEntries && historyEntries.length > 0) {
    const histLen = historyEntries.length;
    for (let i = 1; i <= histLen; i += 1) {
      const currentRecord = historyEntries[i];
      if (currentRecord?.startsWith(routes.wizards.index)) {
        historyEntries.pop();
      }
    }
  }
  const historyEntries2 = router?.history?.entries;
  // console.log('historyEntries2: ', historyEntries2);
};

export const deleteCookiesAndStorageExceptConsent = () => {
  if (typeof window !== 'undefined') {
    localStorage.clear();

    const cookies = document.cookie.split(';');

    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf('=');
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      if (name !== consentKey) {
        document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
      }
    }
  }
};

export const getExpirationTime = dateFutureStr => {
  const dateFuture = new Date(dateFutureStr);
  const dateNow = new Date();
  if (dateFuture < dateNow) return '';
  let remainingSeconds = Math.abs(dateFuture - dateNow) / 1000;
  const days = Math.floor(remainingSeconds / 86400);
  remainingSeconds -= days * 86400;
  const hours = Math.floor(remainingSeconds / 3600) % 24;
  remainingSeconds -= hours * 3600;
  const minutes = Math.floor(remainingSeconds / 60) % 60;
  remainingSeconds -= minutes * 60;
  const seconds = Math.floor(remainingSeconds);
  let difference = '';
  if (days > 0) {
    difference += `${days}d `;
  }
  if (hours > 0) {
    difference += `${hours}h `;
  }
  if (minutes > 0) {
    difference += `${minutes}m `;
  }
  difference += `${seconds}s`;
  return difference;
};

export const evaluateExpressions = (storeState, expressions) => {
  let res = '';
  let str = '';
  for (let i = 0; i < expressions.length; i++) {
    if (expressions[i].operateOn === 'ArrayOfObjects') {
      const arrOfObj = getRef(storeState, expressions[i].key) || [];
      for (let j = 0; j < arrOfObj.length; j++) {
        if (res.length === 0) {
          res = arrOfObj[j][expressions[i].name];
          // console.log('res: ', res);
        } else {
          let exp = '';
          if (expressions[i].operator) {
            exp += calcOperators[expressions[i].operator];
          }
          exp += `${arrOfObj[j][expressions[i].name]}`;
          // console.log('exp1: ', exp);

          // exp = `${res}${exp}`;
          // console.log('exp2: ', exp);

          res += exp;
          // console.log('res: ', res);
        }
      }
      res = eval(res);
    } else if (expressions[i].operateOn === 'individual') {
      str += getRef(storeState, expressions[i].key);
      if (expressions[i].operator && i < expressions.length - 1) {
        str += calcOperators[expressions[i].operator];
      }
      try {
        res += eval(str);
      } catch {
        res = '';
      }
    }
  }
  return res;
};

export const formatTitleToDropDownOptions = title => {
  if (!title) {
    return '';
  }
  if (titleDropdownOptions.includes(title?.toLowerCase())) {
    return title;
  }

  let res = title;
  titleDropdownOptions.map((option, index) => {
    if (option?.toLowerCase() === title?.toLowerCase()) {
      res = option;
    }
    if (option.split('.')[0].toLowerCase() === title.toLowerCase()) {
      res = option;
    }
  });
  return res;
};

export const getObjectIndexFromArray = (arr, key, keyValue) => {
  let res;
  arr.map((obj, index) => {
    if (obj[key] === keyValue) {
      res = index;
    }
  });
  return res;
};

export const getEnv = () => {
  const env = domain?.env;
  if (env === 'development') {
    return 'dev';
  }
  if (env === 'staging') {
    return 'staging';
  }
  if (env === 'production') {
    return 'prod';
  }
  return 'dev';
};

export const getStatusIndicator = (status, autoPayId) => {
  let statusObject = {
    icon: 'done',
    color: 'success',
  };
  switch (status) {
    case 'Active':
      statusObject = {
        icon: 'done',
        color: 'success',
      };
      break;
    case 'Inactive':
      statusObject = {
        icon: 'priority_high',
        color: 'error',
      };
      break;
    case 'Matured':
      statusObject = {
        icon: 'priority_high',
        color: 'warning',
      };
      break;
    case 'Due':
      statusObject = {
        icon: 'report_problem',
        color: 'warning',
      };
      break;
    case 'No Premiums Due':
      statusObject = {
        icon: 'done_all',
        color: 'neutral',
      };
      break;
    case 'No Premiums Due - Paid Up':
      statusObject = {
        icon: 'done_all',
        color: 'success',
      };
      break;
    case 'Not Ready to Renew':
      statusObject = {
        icon: 'done',
        color: 'neutral',
      };
      break;
    case 'Not Ready to Renew - Under Review':
      statusObject = {
        icon: 'announcement',
        color: 'neutral',
      };
      break;
    case 'Cancelled':
      statusObject = {
        icon: 'announcement',
        color: 'error',
      };
      break;
    case 'Changes in progress':
      statusObject = {
        icon: 'announcement',
        color: 'warning',
      };
      break;

    case 'Recurring Payment Enabled':
      statusObject = {
        icon: 'auto_mode',
        color: 'neutral',
        link: autoPayId && `${routes.cart.autoPayCenter}?autoPayId=${autoPayId}`,
      };
      break;

    case 'Recurring Payment Active':
      statusObject = {
        icon: 'auto_mode',
        color: 'success',
        link: autoPayId && `${routes.cart.autoPayCenter}?autoPayId=${autoPayId}`,
      };
      break;

    case 'Recurring Payment Paused':
      statusObject = {
        icon: 'auto_mode',
        color: 'warning',
        link: autoPayId && `${routes.cart.autoPayCenter}?autoPayId=${autoPayId}`,
      };
      break;

    case 'Recurring Payment Failed':
      statusObject = {
        icon: 'auto_mode',
        color: 'error',
        link: autoPayId && `${routes.cart.autoPayCenter}?autoPayId=${autoPayId}`,
      };
      break;

    case 'Recurring Payment Pending':
      statusObject = {
        icon: 'auto_mode',
        color: 'warning',
        link: autoPayId && `${routes.cart.autoPayCenter}?autoPayId=${autoPayId}`,
      };
      break;

    case 'Added To Cart':
      statusObject = {
        icon: 'shopping_cart',
        color: 'neutral',
        link: routes.cart.index,
      };
      break;
    case 'Expired':
      statusObject = {
        icon: 'priority_high',
        color: 'error',
      };
      break;
    case 'First Premium Paid-Under Review':
      statusObject = {
        icon: 'announcement',
        color: 'neutral',
      };
      break;
    case 'First Premium Payment Needed':
      statusObject = {
        icon: 'report_problem',
        color: 'warning',
      };
      break;
    case 'O/S Premium-Payment Needed':
      statusObject = {
        icon: 'report_problem',
        color: 'warning',
      };
      break;
    case 'Ready to Renew':
      statusObject = {
        icon: 'report_problem',
        color: 'warning',
      };

      break;

    case 'Completed':
      statusObject = {
        icon: 'done',
        color: 'success',
      };
      break;

    case 'Processing':
      statusObject = {
        icon: 'sync',
        color: 'warning',
      };
      break;

    case 'Rejected':
      statusObject = {
        icon: 'report_problem',
        color: 'error',
      };
      break;

    case 'Submitted':
      statusObject = {
        icon: 'announcement',
        color: 'info',
      };
      break;
    case 'Approved':
      statusObject = {
        icon: 'done',
        color: 'success',
      };
      break;
    case 'Pending':
      statusObject = {
        icon: 'sync',
        color: 'info',
      };
      break;
    case 'Draft':
      statusObject = {
        icon: 'rotate_left',
        color: 'neutral',
      };
      break;
    default:
      statusObject = {
        icon: 'priority_high',
        color: 'warning',
      };
      break;
  }

  statusObject.status = status;
  return statusObject;
};

export const addAlpha = (color, opacity) => {
  // coerce values so ti is between 0 and 1.
  const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
  return color + _opacity.toString(16).toUpperCase();
};

export const formatMode = mode => {
  const frq = mode?.toLowerCase();
  const mapping = {
    annually: 'Annual',
    annual: 'Annual',
    monthly: 'Monthly',
    quarterly: 'Quarterly',
    semiannually: 'Semi-Annual',
    'semi-annual': 'Semi-Annual',
    'semi annual': 'Semi-Annual',
  };
  return mapping[frq];
};

export const formatModeNoun = mode => {
  const frq = mode?.toLowerCase();
  const mapping = {
    annually: 'Year',
    annual: 'Year',
    monthly: 'Month',
    quarterly: 'Quarter',
    semiannually: 'Half Year',
    'semi-annual': 'Half Year',
    'semi annual': 'Half Year',
  };
  return mapping[frq];
};

export const getButtonColorFromVariant = (variant, important = false) => {
  let buttonColor = 'secondaryButton';
  if (variant === 'text') {
    buttonColor = 'tertiaryButton';
  }
  if (variant === 'outlined') {
    buttonColor = 'secondaryButton';
  }
  if (variant === 'contained') {
    buttonColor = 'primaryButton';
  }
  if (variant === 'contained' && important) {
    buttonColor = 'importantButton';
  }
  return buttonColor;
};

export const formatAlpha2 = initialAlpha2 => {
  let alpha2 = initialAlpha2;
  if (alpha2 === 'JJ') {
    alpha2 = 'JM';
  }

  return alpha2;
};

export const unFormatAlpha2 = initialAlpha2 => {
  let alpha2 = initialAlpha2;
  if (alpha2 === 'JM') {
    alpha2 = 'JJ';
  }

  return alpha2;
};

export const isPwa = () => {
  return ['fullscreen', 'standalone', 'minimal-ui'].some(
    displayMode => window.matchMedia(`(display-mode: ${displayMode})`).matches,
  );
};

export const getAppMode = () => {
  let displayMode = 'browser';
  if (navigator.standalone || isPwa() || document.referrer.includes('android-app://')) {
    displayMode = 'standalone';
  }

  return displayMode;
};

export const getNationalityOptions = () => {
  return countriesWithAlpha2;
};

export const getDutchPolicyStatus = (state, type, isAgent) => {
  let statuses;
  // Type quotation means action needed from user
  if (type === 'quotation' && state !== 'canceled') {
    statuses = { name: 'Pending', icon: 'error', color: '#C0AB0C' };
  }

  // Quote get canceled
  if (state === 'canceled') {
    statuses = { name: 'Canceled', icon: 'cancel', color: '#AC0033' };
  }

  // for nom stp
  if (state === 'pending_stp') {
    statuses = { name: 'Evaluating', icon: 'autorenew', color: '#31006F' };
  }

  // payment not done yet
  if (state === 'pending') {
    statuses = { name: isAgent ? 'Unpaid' : 'Pending', icon: 'error', color: '#E86D1F' };
  }

  // Payment done
  if (state === 'active') {
    statuses = { name: 'Paid', icon: 'check_circle', color: '#00796B' };
  }

  // Quote/policy get exprired
  if (state === 'expired') {
    statuses = { name: 'Expired', icon: 'do_not_disturb_on', color: '#A2A8AB' };
  }
  return statuses;
};

export const removeSessionData = () => {
  removeSessionStorage('globalGuestSessionId');
  removeSessionStorage('guestSessionMobile');
  removeSessionStorage('guestSessionTrnNumber');
  removeSessionStorage('guestSessionEmail');
  removeSessionStorage('guestSessionLastName');
  removeSessionStorage('guestSessionFirstName');
  removeSessionStorage('guestSessionVerifyEmail');
};

export const addDutchCompensationInfo = webstarCompensationDetails => {
  if (
    !Array.isArray(webstarCompensationDetails) ||
    webstarCompensationDetails.length == 0
  )
    return;
  webstarCompensationDetails = webstarCompensationDetails.filter(
    compensationDetails => !!compensationDetails.country,
  );
  return dutchCountries?.reduce((acc, countryDetails) => {
    if (!countryDetails.value) return acc;
    const data = {
      [`${countryDetails.value}-WebstarDetails`]:
        webstarCompensationDetails.filter(
          details => details.country.toLowerCase() === countryDetails.value.toLowerCase(),
        ) || [],
    };
    return { ...acc, ...data };
  }, {});
};

export const adjustDutchPolicyStatus = (status, isAgent) => {
  if (status.toLowerCase() === 'active') {
    return 'paid';
  }
  if (status === 'pending' && isAgent) {
    return 'unpaid';
  }
  return status;
};

export const getDutchMappedStatusData = status => {
  const statusInfo = {
    pending: {
      name: 'Pending',
      icon: 'schedule',
      color: '#C0AB0C',
    },
    evaluating: {
      name: 'Evaluating',
      icon: 'autorenew',
      color: '#31006F',
    },
    canceled: {
      name: 'Canceled',
      icon: 'cancel',
      color: '#AC0033',
    },
    unpaid: {
      name: 'Unpaid',
      icon: 'error',
      color: '#E86D1F',
    },
    paid: {
      name: 'Paid',
      icon: 'check_circle',
      color: '#00796B',
    },
    expired: {
      name: 'Expired',
      icon: 'do_not_disturb_on',
      color: '#A2A8AB',
    },
    quotation: {
      name: 'Quotations',
      color: '#31006F',
    },
    pending_policy: {
      name: 'Pending Policies',
      color: '#31006F',
    },
    myinsured: {
      name: 'My Insureds',
      color: '#31006F',
    },
  };

  return statusInfo[status] || {};
};

export const isCustomerOrAgent = auth => {
  const isCustomer = auth?.user?.customer?.id && auth?.usertype === customerType;
  const isAgent = auth?.user?.agent && auth?.usertype === agentType;
  return auth?.isAuthenticated && (isCustomer || isAgent);
};

export const filterDutchHomePageProductsForAgent = (
  homePageProducts,
  availableProductsForAgent,
) => {
  const productTitleToNameMap = {
    'Short-term Travel insurance': 'shortTravel',
    'Multi-Trip Travel insurance': 'privateMultiTripTravel',
    'Liability insurance': 'privateLiability',
    'Life Insurance': '',
    Pension: '',
    Mortgage: '',
    'Health insurance (MediFlex)': '',
    'Auto insurance': 'privateMotor',
    'Motorcycle insurance': 'privateScooter',
    'Premium home insurance': 'privateHome',
    'Premium contents insurance': 'privateHomeContents',
    'Basic home insurance': 'privateHome',
    'Basic contents insurance': 'privateHomeContents',
    'Home and Content insurance': 'privateHomeContents',
    'Personal Accident Insurance': 'privatePersonalAccident',
    'Construction all risk insurance': 'commercialConstructionAllRisk',
    'User liability insurance': 'commercialLiabilityUser',
    'Owner liability insurance': 'commercialLiabilityOwner',
    'User & Owner liability insurance': 'commercialLiabilityBoth',
    'Fire property insurance': 'commercialPropertyFire',
    'Perfect property insurance': 'commercialPropertyPerfect',
    'Superflex property insurance': 'commercialPropertyFlex',
    'Home insurance': 'privateHome',
    'Contents insurance': 'privateHomeContents',
    'Home and Contents insurance': 'privateHomeContents',
  };

  // Filter child products based on available products for the agent
  homePageProducts.forEach(parentProduct => {
    // if (parentProduct?.title === 'More') return; // Skip products with title 'More'

    // Filter child products whose productName is in the available products list
    const filteredChildProducts = parentProduct.childProducts.filter(childProduct => {
      const productName = productTitleToNameMap[childProduct.title]; // Map the title to productName
      return availableProductsForAgent.includes(productName);
    });

    parentProduct.childProducts = filteredChildProducts; // Update the child products list
  });

  // Filter out parent products that have no child products left
  return homePageProducts.filter(
    parentProduct => parentProduct?.childProducts?.length > 0,
  );
};

export const getDutchPolicyStatusIcon = status => {
  const dutchPolicyStatusIconMap = {
    Paid: {
      name: 'Paid',
      icon: 'check_circle',
      color: '#00796B',
    },
    Cancelled: {
      name: 'Canceled',
      icon: 'cancel',
      color: '#AC0033',
    },
    UnPaid: {
      name: 'UnPaid',
      icon: 'error',
      color: '#E86D1F',
    },
  };

  return dutchPolicyStatusIconMap[status || 'Paid'];
};
