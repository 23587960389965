import { routerGetCall, routerPostCall } from '../../axios';

export const getQuotesUrl = `/accounts/getAllQuotes`;
const getQuotesByIdsUrl = `/accounts/quotes/by-ids`;
const getQuotes = (state, params) => {
  const url = getQuotesUrl;
  return routerGetCall(state, url, params);
};
const getQuotesByIds = (state, body) => {
  const url = getQuotesByIdsUrl;
  return routerPostCall(state, url, body);
};
export default { getQuotes, getQuotesByIds };
