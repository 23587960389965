export const SAVE_PREMIUM_INDICATION_REQUEST = 'SAVE_PREMIUM_INDICATION_REQUEST';
export const SAVE_PREMIUM_INDICATION_SUCCESS = 'SAVE_PREMIUM_INDICATION_SUCCESS';
export const SAVE_PREMIUM_INDICATION_ERROR = 'SAVE_PREMIUM_INDICATION_ERROR';
export const GET_PREMIUM_INDICATION_REQUEST = 'GET_PREMIUM_INDICATION_REQUEST';
export const GET_PREMIUM_INDICATION_SUCCESS = 'GET_PREMIUM_INDICATION_SUCCESS';
export const GET_PREMIUM_INDICATION_ERROR = 'GET_PREMIUM_INDICATION_ERROR';
export const UPDATE_PREMIUM_INDICATION_REQUEST = 'UPDATE_PREMIUM_INDICATION_REQUEST';
export const UPDATE_PREMIUM_INDICATION_SUCCESS = 'UPDATE_PREMIUM_INDICATION_SUCCESS';
export const UPDATE_PREMIUM_INDICATION_ERROR = 'UPDATE_PREMIUM_INDICATION_ERROR';
export const DELETE_PREMIUM_INDICATION_REQUEST = 'DELETE_PREMIUM_INDICATION_REQUEST';
export const DELETE_PREMIUM_INDICATION_SUCCESS = 'DELETE_PREMIUM_INDICATION_SUCCESS';
export const DELETE_PREMIUM_INDICATION_ERROR = 'DELETE_PREMIUM_INDICATION_ERROR';
