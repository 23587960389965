import { RESET_SINGLE_REQUEST_API_SUCCESS } from '../../../constants/requestActionNames';

function resetRequestStateSuccess(prod) {
  return {
    type: RESET_SINGLE_REQUEST_API_SUCCESS,
    payload: prod,
  };
}

export function resetRequestStateAction(data) {
  return resetRequestStateSuccess(data);
}

export default {
  resetRequestStateAction,
};
