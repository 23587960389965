import { STORE_CAMPAIGN_SUCCESS } from '../../../constants/diskActionNames';

export function storeCampaignAction(data) {
  return {
    type: STORE_CAMPAIGN_SUCCESS,
    payload: data,
  };
}

export default {
  storeCampaignAction,
};
