import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ModalAlert from '../modalAlert';
import { updateTempState } from '../../../actions/temp';
import { useRouter } from '../../../hooks';

export default function Settings() {
  const dispatch = useDispatch();
  const router = useRouter();
  const alertPopUp = useSelector(state => state.temp.alertPopUp);
  const onModalClose = useSelector(state => state.temp.onClose);

  const handleClose = () => {
    if (onModalClose) onModalClose();
    dispatch(updateTempState.updateTempStateAction({ alertPopUp: null }));
  };

  const validUrl =
    !alertPopUp?.onlyShowOnUrl || router?.pathname?.includes(alertPopUp?.onlyShowOnUrl);

  return (
    <div>
      {alertPopUp && alertPopUp?.content && validUrl && (
        <ModalAlert
          open={alertPopUp}
          title={alertPopUp?.title}
          content={alertPopUp?.content}
          dismissiveAction={alertPopUp?.dismissiveAction}
          confirmingAction={alertPopUp?.confirmingAction}
          setCloseAsDismissiveAction={alertPopUp?.setCloseAsDismissiveAction}
          maxWidth={alertPopUp?.maxWidth}
          onClose={() => handleClose()}
          isBlocking={alertPopUp?.blockEscape}
          allowFullscreen={alertPopUp?.allowFullscreen}
        />
      )}
    </div>
  );
}
