import {
  GET_MATURED_PENSION_API_REQUEST,
  GET_MATURED_PENSION_API_SUCCESS,
  GET_MATURED_PENSION_API_ERROR,
} from '../../../constants/portfolio/maturedPensionActionNames';
import { getMaturedPensionApi } from '../../../apis/portfolio';

function getMaturedPensionRequest() {
  return {
    type: GET_MATURED_PENSION_API_REQUEST,
    payload: true,
  };
}
function getMaturedPensionSuccess(prod, params) {
  return {
    type: GET_MATURED_PENSION_API_SUCCESS,
    payload: prod,
    params,
  };
}
function getMaturedPensionError(err) {
  return {
    type: GET_MATURED_PENSION_API_ERROR,
    payload: err,
  };
}

export function getMaturedPensionAction(params) {
  return (dispatch, getState) => {
    const state = getState();
    dispatch(getMaturedPensionRequest());
    return getMaturedPensionApi
      .getMaturedPension(state, params)
      .then(data => {
        dispatch(getMaturedPensionSuccess(data, params));
        return data;
      })
      .catch(error => {
        dispatch(getMaturedPensionError(error));
        throw error;
      });
  };
}

export default {
  getMaturedPensionAction,
};
