import {
  SET_MODAL_CONTENT_SUCCESS,
  CHANGE_REDIRECT_SUCCESS,
  OPEN_CHAT_BOT_SUCCESS,
  GET_GENERIC_CONTENTS_API_REQUEST,
  GET_GENERIC_CONTENTS_API_SUCCESS,
  GET_GENERIC_CONTENTS_API_ERROR,
  GET_SURVEY_API_ERROR,
  GET_SURVEY_API_REQUEST,
  GET_SURVEY_API_SUCCESS,
} from '../../constants/miscActionNames';
import { DEFAULT_CACHE_KEY, generateCacheTTL } from '../../utils/functions/cache';

export const initialState = {
  modalContent: null,

  redirectUrl: null,
  openChatBot: false,
  isGenericContentError: false,
  isGenericContentPending: false,
  isGenericContentSuccess: false,
  genericContents: [],

  isSurveyError: false,
  isSurveyPending: false,
  isSurveySuccess: false,
  survey: null,
  isRehydrated: false,
  [DEFAULT_CACHE_KEY]: null,
};

function miscReducer(state = initialState, action) {
  switch (action.type) {
    case SET_MODAL_CONTENT_SUCCESS: {
      const modalContent = action?.payload || null;
      // const wizardData = { ...response };
      // const allWizardData = { ...state.allWizardData, ...response };

      return {
        ...state,
        modalContent,
      };
    }
    case OPEN_CHAT_BOT_SUCCESS: {
      return {
        ...state,
        openChatBot: action?.payload,
      };
    }

    case CHANGE_REDIRECT_SUCCESS: {
      return {
        ...state,
        redirectUrl: action?.payload,
      };
    }

    case GET_GENERIC_CONTENTS_API_REQUEST:
      return {
        ...state,
        isGenericContentPending: true,
        isGenericContentError: false,
        isGenericContentSuccess: false,
      };
    case GET_GENERIC_CONTENTS_API_SUCCESS: {
      // const response = action.payload;
      const genericContents = action?.payload || [];
      const response = action?.payload;
      const cacheKey = action?.cacheKey;
      return {
        ...state,
        isGenericContentPending: false,
        isGenericContentError: false,
        isGenericContentSuccess: true,
        genericContents,
        [DEFAULT_CACHE_KEY]: {
          ...(state[DEFAULT_CACHE_KEY] || {}),
          [cacheKey]: {
            time: generateCacheTTL(),
            payload: response,
          },
        },
      };
    }
    case GET_GENERIC_CONTENTS_API_ERROR:
      return {
        ...state,
        isGenericContentPending: false,
        isGenericContentError: true,
        isGenericContentSuccess: false,
        // error: action.payload,
      };

    case GET_SURVEY_API_REQUEST:
      return {
        ...state,
        isSurveyPending: true,
        isSurveyError: false,
        isSurveySuccess: false,
      };
    case GET_SURVEY_API_SUCCESS: {
      // const response = action.payload;
      const surveys = action?.payload || null;
      const survey = surveys && surveys.length > 0 ? surveys[0] : null;
      return {
        ...state,
        isSurveyPending: false,
        isSurveyError: false,
        isSurveySuccess: true,
        survey,
      };
    }

    case GET_SURVEY_API_ERROR:
      return {
        ...state,
        isSurveyPending: false,
        isSurveyError: true,
        isSurveySuccess: false,
        // error: action.payload,
      };

    default:
      return state;
  }
}
export default miscReducer;
