import { routerGetCall, routerPostCall } from '../../axios';

const getRequests = (state, params) => {
  const url = `/customer/requests`;
  return routerGetCall(state, url, params);
};

const getRequestHistory = (state, params) => {
  const url = `/cms/request/retrieveTransactionHistory`;
  return routerGetCall(state, url, params);
};

const getRequestHistoryNew = (state, params) => {
  const requestHistory = `/cms/transaction-requests`;
  const url = `${requestHistory}?policyId=${params.policyId}`;
  return routerGetCall(state, url);
};

const getRequestHistoryDetails = (state, url, params) => {
  const requestHistoryDetails = `/cms/transaction-requests`;
  const fullUrl = `${requestHistoryDetails}/${url}`;
  return routerGetCall(state, fullUrl, params);
};

const getPendingRequestHistory = (state, params) => {
  const url = `/cms/pending-requests`;
  return routerGetCall(state, url, params);
};

const getPendingRequestHistoryDetails = (state, params) => {
  const pendingRequestHistoryDetails = `/cms/pending-requests`;
  const fullUrl = `${pendingRequestHistoryDetails}/${params?.requestId}`;
  return routerGetCall(state, fullUrl, params);
};

const postApprovePendingRequest = (state, params) => {
  const approvePendingRequest = `/cms/pending-requests`;
  const url = `${approvePendingRequest}/${params.id}/approve`;
  return routerPostCall(state, url, params);
};

const getRejectPendingRequest = (state, params) => {
  const rejectPendingRequest = `/cms/pending-requests`;
  const url = `${rejectPendingRequest}?policyId=${params.policyId}/reject`;
  return routerGetCall(state, url, params);
};
// const getRequestHistory = (state, params) => {
//   const url = `/cms/request/retrieveRequests`;
//   return routerGetCall(state, url, params);
// };
export default {
  getRequests,
  getRequestHistory,
  getRequestHistoryDetails,
  getPendingRequestHistory,
  getPendingRequestHistoryDetails,
  postApprovePendingRequest,
  getRejectPendingRequest,
  getRequestHistoryNew,
};
