import { DEFAULT_CACHE_KEY, generateCacheTTL } from '../../utils/functions/cache';
import {
  UPDATE_NEW_REQUESTS_CURRENT_CACHE_KEY,
  GET_NEW_REQUESTS_API_REQUEST,
  GET_NEW_REQUESTS_API_SUCCESS,
  GET_NEW_REQUESTS_API_ERROR,
  GET_NEXT_NEW_REQUESTS_SUCCESS,
} from '../../constants/newRequestActionNames';
import {
  APPROVE_PENDING_REQUEST_API_SUCCESS,
  REJECT_PENDING_REQUEST_API_SUCCESS,
  CANCEL_PENDING_REQUEST_API_SUCCESS,
  GET_PENDING_REQUEST_HISTORY_DETAILS_API_REQUEST,
  GET_PENDING_REQUEST_HISTORY_DETAILS_API_SUCCESS,
  GET_PENDING_REQUEST_HISTORY_DETAILS_API_ERROR,
  RESET_SINGLE_REQUEST_API_SUCCESS,
} from '../../constants/requestActionNames';
import {
  RESET_INFINITE_SCROLL_SUCCESS,
  CLEAR_REQUESTS_CACHE,
} from '../../constants/miscActionNames';
import {
  uuidv4,
  dateTimeToString,
  stringQuoteToDate,
  isoStringToDate,
  getAllKeys,
  getKeys,
  uniq,
  addArrayOnce,
} from '../../utils/functions';
import { persistKey, statusOk } from '../../utils/variables';

export const createPayload = (payloadArray, lobTypes, cacheKey, body) => {
  if (!Array.isArray(payloadArray)) return null;
  let newRequests = payloadArray?.map((object, index) => {
    const lobObj = lobTypes?.find(lobObject => {
      const lobIndex = object?.lob?.toLowerCase() === lobObject.lob;
      return lobIndex;
    });
    const position = index;
    if (object?.data.email) {
      object.data.email = object?.data?.email?.toLowerCase();
    }
    if (object?.data?.preApprovedAch?.[0]?.checked) {
      object.data.preApprovedACH = 'Yes';
    }
    if (object?.data?.fullTransfer === true) {
      object.data.fullTransfer = 'Yes';
    }
    if (object?.data?.fullTransfer === false) {
      object.data.fullTransfer = 'No';
    }
    if (object?.data?.fullSwitch === true) {
      object.data.fullSwitch = 'Yes';
    }
    if (object?.data?.fullSwitch === false) {
      object.data.fullSwitch = 'No';
    }

    if (object?.data?.amount) {
      object.data.amount = Number(object.data.amount);
    }

    const request = {
      ...object,
      index: position,
      id: object?._id, // object?._id, // ? `${object._id}_${position}` : uuidv4(), // `${object?._id}_${uiid}`,
      clientNumber: object?.masterId, // object?._id,
      slug: object?.masterId, // object?._id,
      icon: lobObj?.materialIcon,
      cacheId: `${object?._id}_${cacheKey}`,
      cacheKey,
      formattedAmount: object?.data?.formattedAmount,
      amount: object?.data?.amount,
      currency: object?.data?.currency,
      // dob: object?.dob,
      updatedAt: dateTimeToString(isoStringToDate(object?.updatedAt)),
      createdAt: dateTimeToString(isoStringToDate(object?.createdAt)),
      body,
    };

    const newKeys = getKeys(request);
    const searchKeys = uniq(newKeys);
    request.searchKeys = searchKeys;

    return request;
  });
  newRequests = newRequests?.filter(x => x !== null);

  return newRequests;
};

export const initialState = {
  isPending: false,
  isError: false,
  isSuccess: false,
  response: null,
  error: null,
  allRows: [],
  filters: [],
  count: 0,
  nextItemCount: 0,
  nextScrollCount: 0,
  nextStartIndex: 0,
  searchKeys: [],
  [persistKey]: null,
  [DEFAULT_CACHE_KEY]: null,
  currentCacheKey: '',
  client: null,
  total: 0,
  isPendingRequestHistoryDetailsPending: false,
  isPendingRequestHistoryDetailsError: false,
  isPendingRequestHistoryDetailsSuccess: false,
  pendingRequestHistoryDetailsResponse: null,
};

function newRequestsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_NEW_REQUESTS_API_REQUEST:
      return {
        ...state,
        isPending: true,
        isError: false,
        isSuccess: false,
      };
    case GET_NEW_REQUESTS_API_SUCCESS: {
      const lobTypes = action?.lobTypes;
      const body = action?.body;
      // console.log('action: ', action);
      const cacheKey = action?.cacheKey;
      const rowPayload = createPayload(
        action?.payload?.payload?.[0]?.result?.data,
        lobTypes,
        cacheKey,
        body,
      );
      const newRows = (rowPayload && Array.isArray(rowPayload) && rowPayload) || [];
      // console.log('newRows: ', newRows);
      // console.log('body: ', body);
      const response = action?.payload;
      const newFilters = response?.payload?.[0]?.result?.statuses || [];
      const newTotal = response?.payload?.[0]?.result?.total || 0;

      const isError = response?.responseType !== statusOk;

      const nextStartIndex = body?.nextStartIndex;
      const nextScrollCount = body?.nextScrollCount;
      const nextItemCount = body?.nextItemCount;

      const count = typeof state?.count === 'object' ? { ...state?.count } : {};
      count[cacheKey] = newTotal;

      return {
        ...state,
        total: newTotal,
        isPending: false,
        isError,
        isSuccess: true,
        filters: newFilters,
        response,
        // rows,
        count,
        searchKeys: newRows?.[0]?.searchKeys,
        allRows: newRows,
        // [persistKey]: nowTime,
        currentCacheKey: cacheKey,
        [DEFAULT_CACHE_KEY]: {
          ...(state[DEFAULT_CACHE_KEY] || {}),
          [cacheKey]: {
            time: generateCacheTTL(),
            payload: response,
          },
        },
        nextItemCount,
        nextScrollCount,
        nextStartIndex,
      };
    }

    case GET_NEW_REQUESTS_API_ERROR:
      return {
        ...state,
        isPending: false,
        isError: true,
        isSuccess: false,
        error: action?.payload,
      };

    case UPDATE_NEW_REQUESTS_CURRENT_CACHE_KEY:
      return {
        ...state,
        currentCacheKey: action?.payload,
      };

    case GET_NEXT_NEW_REQUESTS_SUCCESS: {
      const nextStartIndex = action?.payload?.nextStartIndex;
      const nextScrollCount = action?.payload?.nextScrollCount;
      const nextItemCount = action?.payload?.nextItemCount;

      return {
        ...state,
        nextItemCount,
        nextScrollCount,
        nextStartIndex,
      };
    }

    case RESET_INFINITE_SCROLL_SUCCESS: {
      return {
        ...state,

        nextItemCount: 0,
        nextScrollCount: 0,
        nextStartIndex: 0,
      };
    }

    case GET_PENDING_REQUEST_HISTORY_DETAILS_API_REQUEST:
      return {
        ...state,
        isPendingRequestHistoryDetailsPending: true,
        isPendingRequestHistoryDetailsError: false,
        isPendingRequestHistoryDetailsSuccess: false,
        pendingRequestHistoryDetailsResponse: null,
      };
    case GET_PENDING_REQUEST_HISTORY_DETAILS_API_SUCCESS: {
      const newPayload = createPayload([action?.payload?.payload]);
      return {
        ...state,
        isPendingRequestHistoryDetailsPending: false,
        isPendingRequestHistoryDetailsError: false,
        isPendingRequestHistoryDetailsSuccess: true,
        pendingRequestHistoryDetailsResponse: newPayload && newPayload[0],
      };
    }
    case GET_PENDING_REQUEST_HISTORY_DETAILS_API_ERROR:
      return {
        ...state,
        isPendingRequestHistoryDetailsPending: false,
        isPendingRequestHistoryDetailsError: true,
        isPendingRequestHistoryDetailsSuccess: false,
      };

    case RESET_SINGLE_REQUEST_API_SUCCESS:
      return {
        ...state,
        pendingRequestHistoryDetailsResponse: null,
      };

    case APPROVE_PENDING_REQUEST_API_SUCCESS:
    case REJECT_PENDING_REQUEST_API_SUCCESS:
    case CANCEL_PENDING_REQUEST_API_SUCCESS:
    case CLEAR_REQUESTS_CACHE:
      return {
        ...state,
        [persistKey]: null,
        [DEFAULT_CACHE_KEY]: null,
        allRows: initialState.allRows,
        total: initialState.total,
      };

    default:
      return state;
  }
}

export default newRequestsReducer;
