import React, { useState } from 'react';
import classNames from 'classnames';
import { Link as RouterLink } from 'react-router-dom';
import { Grid, Icon, Typography, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useSelector, useDispatch } from 'react-redux';

import { useSmall, useRouter } from '../../../../hooks';
import { Spinner } from '../../../common';
import fatumRedirectApi from '../../../../apis/auth/getFatumRedirectApi';
import { isValidHttpUrl } from '../../../../utils/functions';
import { resetWizard } from '../../../../actions/wizards';

import styles from './style';
import ShareLink from '../../../../containersV2/shareLink';

const useStyles = makeStyles(styles);

function DutchCategoryCard(props) {
  const { product, loading, isAction, openModel } = props;
  const {
    background,
    materialIcon,
    customIcon,
    subTitle,
    title,
    href,
    isExternalLink,
    isOnClickModal,
  } = product;

  const isSmall = useSmall();
  const router = useRouter();
  const dispatch = useDispatch();
  const getProductType = product.type === 'action' || '';
  const classes = useStyles({ ...props, isSmall });
  const { productTypeMore } = props;
  const selectedClient = useSelector(state => state?.auth?.selectedClient);
  const isAuthenticated = useSelector(state => state?.auth?.isAuthenticated);
  const state = useSelector(state => state);
  const finalIcon = customIcon || materialIcon;

  const onClick =
    product.onClick ||
    async function onClick() {
      // Check if isExternalLink is truthy then redirect to external site else navigate user to href
      if (isExternalLink) {
        await redirectToExternalSite(href);
      } else if (isOnClickModal) {
        openModel();
      } else {
        if (!href) return;
        const currentPathArray = router.location.pathname?.split('/');
        const searchParams = router.location.search;
        // Checking if user path includes wizards
        if (Array.isArray(currentPathArray) && currentPathArray.includes('wizards')) {
          // Resetting wizard data before navigating
          dispatch(resetWizard.resetWizardAction());
          // Add stepIndex=0 as search param to href
          const updatedSearchParams = searchParams
            ? `${searchParams}&stepIndex=0`
            : `?stepIndex=0`;
          router.push(`${href}${updatedSearchParams}`);
        } else {
          //
          router.push(href);
        }
      }
    };

  // To redirect to GGF(Temporary purpose)
  const redirectToExternalSite = async href => {
    // Checking for valid url
    if (isValidHttpUrl(href)) {
      const redirectUrl = new URL(href);
      if (isAuthenticated) {
        const res = await fatumRedirectApi.getSessionId(state, {
          customerMasterId: selectedClient ? selectedClient?.masterId : null,
          customerC360Id: selectedClient ? selectedClient?.c360Id : null,
        });
        const sessionId = res?.payload?.sessionId;
        if (sessionId) {
          redirectUrl.searchParams.append('sessionid', sessionId);
        }
      }

      window.location.href = redirectUrl;
    }
  };

  return (
    <Grid
      className={classNames(
        { allinsurances_card_dutch_tour: product.title === 'All insurances' },
        { vehicle_product_card_dutch_tour: product.title === 'Vehicle' },
        classes.quickActionCard,
        { auto_product_card_dutch_tour: product.title === 'Auto insurance' },
        classes.quickActionCard,
        !getProductType ? classes.quickActionCardHeight : classes.insuranceQuickAction,
      )}
      bgcolor={{ background }}
      component={RouterLink}
      // to={currLocation => {
      //   return isExternalLink ? null : href;
      // }}
      // {...(isExternalLink ? {} : { to: href })}
      onClick={onClick}
    >
      <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
        {/* Icon  */}
        <Grid item>
          {finalIcon && (
            <Icon
              data-nosnippet
              item
              className={`${classes.materialIcon} ${productTypeMore &&
                classes.productTypeMoreIcon}`}
            >
              {finalIcon}
            </Icon>
          )}
        </Grid>

        {/* Title  */}
        <Grid item sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {title && (
            <>
              {productTypeMore ? (
                <Typography
                  item
                  className={classNames(
                    classes.titleStyles,
                    classes.typeTitleStyles,
                    classes.moreProductColor,
                  )}
                >
                  {title}
                </Typography>
              ) : (
                <Typography
                  className={`${classes.titleStyles} ${!getProductType &&
                    classes.typeTitleStyles}`}
                >
                  {title}
                </Typography>
              )}
            </>
          )}
        </Grid>
      </Grid>

      {/* Subtitle  */}
      <Grid
        item
        xs={12}
        sx={{
          display: 'flex',
          alignItems: 'center',
          textOverflow: 'ellipsis',
        }}
      >
        {subTitle && (
          <>
            {loading ? (
              <Spinner />
            ) : (
              <Typography className={classes.subTitleStyles}>{subTitle}</Typography>
            )}
          </>
        )}
      </Grid>

      {/* Buttons */}
      <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          component={RouterLink}
          // {...(isExternalLink ? {} : { to: href })}
          className={`${classes.navigateButton} ${!getProductType
            ? classes.bottomNavigationButton
            : classes.bottomNavigationButton
            }`}
          onClick={onClick}
        >
          <Icon
            data-nosnippet
            className={`${classes.icon} ${!getProductType && classes.typeIcon}`}
          >
            chevron_right
          </Icon>
        </Button>
      </Grid>

    </Grid>
  );
}

export default DutchCategoryCard;
