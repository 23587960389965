import {
  GET_SINGLE_INSURANCE_API_REQUEST,
  GET_SINGLE_INSURANCE_API_SUCCESS,
  GET_SINGLE_INSURANCE_API_ERROR,
} from '../../../constants/portfolio/insuranceActionNames';
// import { api } from '../../../utils/variables';
import { getInsurancesApi } from '../../../apis/portfolio';
import { singlePolicyUrl } from '../../../apis/portfolio/getInsurancesApi';
import { checkCacheValid, generateCacheKey } from '../../../utils/functions/cache';
import { dutchTemplate } from '../../../utils/variables';
import { getQuotesApi } from '../../../apis/quotes';

function getSingleInsuranceError(err, body) {
  return {
    type: GET_SINGLE_INSURANCE_API_ERROR,
    payload: err,
    body,
  };
}

function getSingleInsuranceRequest(body) {
  return {
    type: GET_SINGLE_INSURANCE_API_REQUEST,
    payload: true,
    body,
  };
}
function getSingleInsuranceSuccess(
  data,
  body,
  lobTypes,
  templateName = '',
  dontFormat,
  cacheKey,
) {
  return {
    type: GET_SINGLE_INSURANCE_API_SUCCESS,
    payload: data,
    body,
    lobTypes,
    templateName,
    dontFormat,
    cacheKey,
  };
}

export function getSingleInsuranceAction(params, body) {
  // const apiEndpoint = {}; // ...api };
  // const response = getPayloadResponse();
  return (dispatch, getState) => {
    const state = getState();
    const templateName = state?.locations?.yourLocation?.variables?.templateName;
    const lobTypes = state?.locations?.lobCategories;
    const insurances = state?.insurance?.insurances || [];
    const { trimPolicyId } = params;
    let cachedPolicy = null;
    if (trimPolicyId) {
      // console.log('trimPolicyId: ', trimPolicyId);
      cachedPolicy = insurances?.find(
        insurance => insurance?.id?.replace(/\s/g, '') === trimPolicyId,
      );
      // console.log('cachedPolicy: ', cachedPolicy);
      if (cachedPolicy) {
        const cachedResult = { status: 'cached', payload: cachedPolicy };
        const dontFormat = true;
        dispatch(
          getSingleInsuranceSuccess(
            cachedResult,
            body,
            lobTypes,
            templateName,
            dontFormat,
          ),
        );
        return cachedResult;
      }
    }

    const cacheKey = generateCacheKey({
      path: singlePolicyUrl,
      cacheParams: params,
      state,
    });
    const { isCacheValid, cache } = checkCacheValid(getState, 'insurance', cacheKey);
    if (isCacheValid) {
      dispatch(
        getSingleInsuranceSuccess(cache, body, lobTypes, templateName, false, cacheKey),
      );
      return Promise.resolve(cache);
    }
    // // console.log('firstTime1: ', firstTime);
    dispatch(getSingleInsuranceRequest(body));
    return Promise.resolve(null)
      .then(() => getInsurancesApi.getSingleInsurance(state, params))

      .then(data => {
        // // console.log('data: ', data);
        // Update the app state with the results of the API call.
        const isDutchRegion = templateName === dutchTemplate;
        const policyList = [{ id: data?.payload?.id }];
        if (isDutchRegion && policyList.length > 0) {
          // Fetch quotes for the fetched policies.
          getQuotesApi.getQuotesByIds(state, policyList)
            .then(({ payload: quoteData }) => {
              if (quoteData.length > 0) {
                data.payload.quote = quoteData[0]
              }

              dispatch(
                getSingleInsuranceSuccess(data, body, lobTypes, templateName, false, cacheKey),
              );
            })
            .catch(error => {
              dispatch(getSingleInsuranceError(error, body));
            });
        }
        else {
          dispatch(
            getSingleInsuranceSuccess(data, body, lobTypes, templateName, false, cacheKey),
          );
        }
        return data;
      })
      .catch(error => {
        dispatch(getSingleInsuranceError(error, body));
        throw error;
      });
  };
}

export default {
  getSingleInsuranceAction,
};
