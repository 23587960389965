import { RESET_INFINITE_SCROLL_SUCCESS } from '../../../constants/miscActionNames';

function resetInfiniteScrollSuccess(prod) {
  return {
    type: RESET_INFINITE_SCROLL_SUCCESS,
    payload: prod,
  };
}

export function resetInfiniteScrollAction(data) {
  return resetInfiniteScrollSuccess(data);
}

export default {
  resetInfiniteScrollAction,
};
