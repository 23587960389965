import { getGuestRegistrationDetailsApi } from '../../../apis/auth';
import {
    GUEST_REGISTRATION_DETAILS_API_REQUEST,
    GUEST_REGISTRATION_DETAILS_API_ERROR,
    GUEST_REGISTRATION_DETAILS_API_SUCCESS
} from '../../../constants/authActionNames';;


function getGuestRegistrationDetailsRequest() {
    return {
        type: GUEST_REGISTRATION_DETAILS_API_REQUEST,
        payload: true,
    };
}
function getGuestRegistrationDetailsSuccess(data) {
    return {
        type: GUEST_REGISTRATION_DETAILS_API_SUCCESS,
        payload: data
    };
}

function getGuestRegistrationDetailsError(err) {
    return {
        type: GUEST_REGISTRATION_DETAILS_API_ERROR,
        payload: err,
    };
}

export function getGuestRegistrationDetailsAction(params) {
    return (dispatch, getState) => {
        const state = getState();
        dispatch(getGuestRegistrationDetailsRequest());
        return getGuestRegistrationDetailsApi.getGuestRegistrationDetails(state, params)
            .then(data => {
                console.log("daattata", data)
                dispatch(getGuestRegistrationDetailsSuccess(data.payload));
                return data;
            })
            .catch(error => {
                dispatch(getGuestRegistrationDetailsError(error));
                throw error;
            });
    };
}

export default {
    getGuestRegistrationDetailsAction
};
