import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import QRCode from 'qrcode.react';
import { makeStyles } from '@mui/styles';

import {
  Grid,
  IconButton,
  Button,
  Typography,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Select,
  MenuItem,
} from '@mui/material';

// Icons
import MailOutlinedIcon from '@mui/icons-material/MailOutlined';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CloseIcon from '@mui/icons-material/Close';

import { displaySuccess } from '../../utils/functions';
import { domain } from '../../utils/variables';
import { useSmall } from '../../hooks';

import styles from './style';

const useStyles = makeStyles(styles);

// Social media informations
const socialMediaInfo = [
  {
    name: 'FaceBook',
    icon: '/images/socialMedia/fb.png',
    url: 'https://www.facebook.com',
  },
  {
    name: 'X',
    icon: '/images/socialMedia/twitter.png',
    url: 'https://x.com', // Updated URL for X (formerly Twitter)
  },
  {
    name: 'Instagram',
    icon: '/images/socialMedia/instragram.png',
    url: 'https://www.instagram.com',
  },
  {
    name: 'LinkedIn',
    icon: '/images/socialMedia/ined.png',
    url: 'https://www.linkedin.com',
  },
  {
    name: 'Telegram',
    icon: '/images/socialMedia/telegram.png',
    url: 'https://t.me',
  },
  {
    name: 'Threads',
    icon: '/images/socialMedia/thread.png',
    url: 'https://www.threads.net',
  },
  {
    name: 'WhatsApp',
    icon: '/images/socialMedia/whatsApp.png',
    url: 'https://wa.me',
  },
  {
    name: 'Messenger',
    icon: '/images/socialMedia/messenger.png',
    url: 'https://www.messenger.com',
  },
];

const ShareLink = ({ isModalOpen, setModalOpen }) => {
  const classes = useStyles();
  const inputRef = useRef(null);
  const isSmall = useSmall();

  // Here user will be agent only as this component is decicated to agent only
  const user = useSelector(state => state?.auth?.user);
  const referrer = useSelector(state => state?.auth?.user?.agent?.referrerId);
  const webstarDetails = useSelector(state => state?.auth?.user?.agent?.webstarDetails);
  const [selectedNumber, setSelectedNumber] = useState(webstarDetails?.[0]?._id)

  const referralLink = `${domain.pwa}/home?referrer=${referrer}&commission=${selectedNumber ?? ''}`;

  // CopyHandler
  function copyToClipboard() {
    navigator.clipboard.writeText(referralLink);
    displaySuccess(
      {
        label: 'Copied!',
        message: 'Link is copied',
      },
      null,
      { timeOut: 3000 },
    );
  }

  const handleClose = () => {
    setModalOpen(false);
  };

  const fullName = `${user?.firstName} ${user?.lastName}`;

  // card component
  const DisplayAgentInfo = ({ email, phone }) => {
    return (
      <Grid container spacing={1} direction="column" sx={{ mt: 1 }}>
        {email && (
          <Grid item xs="auto" className={classes.informationText}>
            <MailOutlinedIcon sx={{ fontSize: '0.6rem' }} />
            &nbsp;{email}
          </Grid>
        )}

        {phone && (
          <Grid item xs="auto" style={{ display: 'flex', alignItems: 'center' }}>
            <LocalPhoneOutlinedIcon />
            &nbsp;{phone}
          </Grid>
        )}
      </Grid>
    );
  };

  // share redirectional handler
  const redirectionHandler = url => {
    window.open(url, '_blank');
  };

  return (
    <>
      <Dialog
        open={isModalOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" className={classes.heading}>
          Refer a Customer
          <IconButton aria-label="close" onClick={handleClose}>
            <CloseIcon className={classes.closeButton} />
          </IconButton>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Grid container spacing={1}>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={selectedNumber}
              label="Age"
              sx={{ width: '100%', marginBottom: '10px' }}
              onChange={e => setSelectedNumber(e.target.value)}
            >
              {
                Array.isArray(webstarDetails) && webstarDetails?.map((webstarNumber, index) => (
                  <MenuItem key={index} value={webstarNumber?._id}>{`${webstarNumber?.country} ${webstarNumber?.producerNumber}/${webstarNumber?.commissionNumber}`}</MenuItem>
                ))
              }
            </Select>

            {/* Card will show for big screen only */}
            {!isSmall && (
              <Grid
                item
                xs={12}
                md={8}
                style={{
                  backgroundImage: 'url(/images/card1.png)',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  display: 'flex',
                  boxSizing: 'border-box',
                  position: 'relative',
                  paddingTop: '8%',
                }}
              >
                <div style={{ zIndex: 1 }}>
                  <h4 style={{ color: 'white' }}>{fullName}</h4>
                  <DisplayAgentInfo email={user?.agent?.email} />
                </div>
              </Grid>
            )}

            <Grid item xs={12} md={4} align="center">
              <Typography className={classes.scanLabel} align="center">
                Scan this QR Code
              </Typography>

              {/* QR code of shareble link  */}
              {referralLink && (
                <QRCode value={referralLink} style={{ marginTop: '10px' }} />
              )}

              <Typography className={classes.scanLabel} align="center">
                or
              </Typography>

              {/* Copy link button  */}
              <Button
                variant="outlined"
                className={classes.buttonText}
                onClick={() => copyToClipboard()}
              >
                <ContentCopyIcon /> &nbsp;Copy Link
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.scanLabel} sx={{ mt: 2 }}>
                Share via
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>

        {/* Social Media Share Links */}
        <DialogActions className={classes.actions}>
          {socialMediaInfo &&
            socialMediaInfo?.map((item, index) => {
              return (
                <Button key={index} onClick={() => redirectionHandler(item?.url)}>
                  <img
                    alt={item?.name}
                    src={item?.icon}
                    className={classes.socialMediaIcons}
                  />
                </Button>
              );
            })}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ShareLink;
