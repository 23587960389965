import { DEFAULT_CACHE_KEY, generateCacheTTL } from '../../../utils/functions/cache';
import {
  GET_INSURANCES_API_REQUEST,
  GET_INSURANCES_API_SUCCESS,
  GET_INSURANCES_API_ERROR,
  GET_INSURANCE_INVESTMENTS_ERROR,
  GET_INSURANCE_INVESTMENTS_REQUEST,
  GET_INSURANCE_INVESTMENTS_SUCCESS,
  GET_NEXT_INSURANCES_SCROLL_SUCCESS,
  GET_INSURANCE_FILTER_SETTINGS_API_REQUEST,
  GET_INSURANCE_FILTER_SETTINGS_API_SUCCESS,
  GET_INSURANCE_FILTER_SETTINGS_API_ERROR,
  GET_SINGLE_INSURANCE_API_REQUEST,
  GET_SINGLE_INSURANCE_API_SUCCESS,
  GET_SINGLE_INSURANCE_API_ERROR,
  RESET_INSURANCE,
  UPDATE_TOTALS_SUCCESS,
  UPDATE_INSURANCE_BODY_SUCCESS,
  RESET_CACHE_FILTER_PARAMS,
} from '../../../constants/portfolio/insuranceActionNames';
import {
  RENEW_POLICY_API_SUCCESS,
  AMEND_POLICY_API_SUCCESS,
} from '../../../constants/renewalActionNames';
import {
  SIGNIN_API_SUCCESS,
  SELECT_CLIENTS_SUCCESS,
} from '../../../constants/authActionNames';
import {
  ADD_RENEWAL_TO_CART_API_SUCCESS,
  PAY_NOW_API_SUCCESS,
  PAY_FIRST_PREMIUM_API_SUCCESS,
  MAKE_FAC_PAYMENT_API_SUCCESS,
} from '../../../constants/cartActionNames';
import {
  GET_MATURED_PENSION_API_REQUEST,
  GET_MATURED_PENSION_API_SUCCESS,
  GET_MATURED_PENSION_API_ERROR,
} from '../../../constants/portfolio/maturedPensionActionNames';
import {
  CLEAR_PORTFOLIO_CACHE,
  RESET_INFINITE_SCROLL_SUCCESS,
} from '../../../constants/miscActionNames';
import {
  DELETE_RECURRING_PAYMENT_SUCCESS,
  PAUSE_RECURRING_PAYMENT_SUCCESS,
  ACTIVATE_RECURRING_PAYMENT_SUCCESS,
} from '../../../constants/recurringPaymentActionNames';

// import initialData from './initialData';
import payloadData from './payloadData.json';
import {
  persistKey,
  searchKey,
  investmentLob,
  healthLob,
  motorLob,
  homeLob,
  otherLob,
  lifeLob,
  pensionLob,
  dutchTemplate,
  groupPlanType,
} from '../../../utils/variables';
import {
  stringToDate,
  isoStringToDate,
  uuidv4,
  dateToString,
  removeAllSpaces,
  generateSearchWords,
  getKeys,
  getAllKeys,
  uniq,
  toTitleCase,
  nth,
  keySort,
  dateToReadableString,
  formatMode,
  formatModeNoun,
  formatDataTableValue,
  isCurrentMonth,
} from '../../../utils/functions';
import { filterInsuranceStatuses } from '../../../selectors/data';
import {
  GET_POLICY_DOCUMENTS_API_REQUEST,
  GET_POLICY_DOCUMENTS_API_SUCCESS,
  GET_POLICY_DOCUMENTS_API_ERROR,
  DOWNLOAD_POLICY_DOCUMENT_API_REQUEST,
  DOWNLOAD_POLICY_DOCUMENT_API_SUCCESS,
  DOWNLOAD_POLICY_DOCUMENT_API_ERROR,
} from '../../../constants/portfolio/policyDocumentActionNames';
import {
  GET_POLICY_EXTERNAL_LINKS_API_ERROR,
  GET_POLICY_EXTERNAL_LINKS_API_REQUEST,
  GET_POLICY_EXTERNAL_LINKS_API_SUCCESS,
} from '../../../constants/portfolio/policyExternalLinksActionNames';
import { createAccountsPayload } from '../investments';

const slugify = require('slugify');

let searchKeys = [];
export const initialState = {
  response: null, // newPortfolioData
  isPending: false,
  isError: false,
  isSuccess: false,
  isMaturedPending: false,
  isMaturedError: false,
  isMaturedSuccess: false,
  error: {},
  insurance: [],
  insurances: [],
  accounts: [],
  searchKeys,
  // insuranceCollections: [],
  [persistKey]: null,
  nextItemCount: 0,
  nextScrollCount: 0,
  nextStartIndex: 0,
  scrollKey: null,
  firstTime: false,
  maturedPension: null,
  total: {},
  isGetInsuranceFilterSettingsPending: false,
  isGetInsuranceFilterSettingsError: false,
  isGetInsuranceFilterSettingsSuccess: false,
  insuranceFilterSettingsResponse: null,
  insuranceFilterSettings: null,
  insuranceFilterSettingsError: null,
  isSingleInsurancePolicyPending: false,
  isSingleInsurancePolicyError: false,
  isSingleInsurancePolicySuccess: false,
  singleInsurancePolicyResponse: null,
  insurancePolicy: null,
  singleInsurancePolicyError: null,
  isPolicyDocumentsPending: false,
  isPolicyDocumentsError: false,
  isPolicyDocumentsSuccess: false,
  policyDocuments: [],
  isDownloadPolicyDocumentPending: false,
  isDownloadPolicyDocumentError: false,
  isDownloadPolicyDocumentSuccess: false,
  downloadPolicyDocument: null,
  isPolicyExternalLinksPending: false,
  isPolicyExternalLinksError: false,
  isPolicyExternalLinksSuccess: false,
  policyExternalLinks: [],
  [DEFAULT_CACHE_KEY]: null,
  currentCacheKey: '',
  currentFilterCacheKey: '',
  cacheFilterParams: {},
};

const createMaturedPension = maturedPensionPayload => {
  return {
    ...maturedPensionPayload,
    policyNumber: maturedPensionPayload?.policyNumber,
    yearToDatePension: maturedPensionPayload?.YTDPension,
    yearToDateTaxPaid: maturedPensionPayload?.YTDTax,
    lastPaidPension: dateToString(
      isoStringToDate(maturedPensionPayload?.lastPensionDate),
    ),
    monthlyPension: maturedPensionPayload?.monthlyPension,
    beneficiary: maturedPensionPayload?.beneficiaries,
  };
};

const createOtherDiscounts = payloadArray => {
  if (!Array.isArray(payloadArray)) return null;
  return payloadArray.map((object, index) => {
    return {
      ...object,
      index,
      id: object?.id,
      discountName: object?.discountName,
      amount: object?.amount,
    };
  });
};

const createDiscounts = payloadArray => {
  if (!Array.isArray(payloadArray)) return null;
  return payloadArray.map((object, index) => {
    return {
      ...object,
      index,
      id: object?.id,
      noClaimDiscount: object?.noClaimDiscount,
      fleetDiscount: object?.fleetDiscount,
      antiTheft: object?.antiTheft,
      otherDiscounts: createOtherDiscounts(object?.otherDiscounts),
    };
  });
};

const createCoverages = (payloadArray, templateName = '') => {
  if (!Array.isArray(payloadArray)) return null;
  const isDutchRegion = templateName === dutchTemplate;
  payloadArray.filter(x => x?.status === 'Active');
  return payloadArray.map((object, index) => {
    return {
      ...object,
      index,
      id: object?.id,
      name: object?.name,
      status: object?.status,
      startDate: dateToString(isoStringToDate(object?.startDate)),
      premAmt: object?.premiumAmount || object?.premAmt,
      faceAmt: object?.faceAmt,
      fundValue: object?.fundValue,
      currency: object?.currency,
      paymentMode: object?.paymentMode,
      maximumBenefit: object?.maximumBenefit,
      deductiblePerPerson: object?.deductiblePerPerson,
      deductiblePerFamily: object?.deductiblePerFamily,
    };
  });
};

const createSubjectMatters = payloadArray => {
  if (!Array.isArray(payloadArray)) return null;
  return payloadArray.map((object, index) => {
    return {
      ...object,
      index,
      id: object?.id,
      buildingSumInsured: object?.buidlingSumInsured,
      swimmingPool: object?.swimmingPool,
      general: object?.general,
      tenant: object?.tenant,
      electrical: object?.electrical,
      antenna: object?.antenna,
      satellite: object?.sateliite,
      perils: object?.perils,
    };
  });
};

const createInsuredItems = (payloadArray, type, templateName = '') => {
  const isDutchRegion = templateName === dutchTemplate;
  if (!Array.isArray(payloadArray)) return null;
  return payloadArray.map((object, index) => {
    return {
      ...object,
      index,
      id: object?.id,
      coverageType: object?.coverageType,
      insuredItem: object?.insuredItem,
      insuredItemLocation: object?.insuredItemLocation,
      sumInsured: object?.sumInsured,
      premium: object?.premium,
      occupancy: object?.occupancy,
      addedOn: dateToString(isoStringToDate(object?.addedOn)),
      vehicleMakeandModel: object?.vehicleMakeandModel,
      vehicleYear: object?.vehicleYear,
      registrationNumber: object?.registrationNumber,
      engineNumber: object?.engineNumber,
      chassisNumber: object?.chassisNumber,
      sumInsuredLimit: object?.sumInsuredLimit,
      premiumNumber: object?.premiumNumber,
      terminatedOn: dateToString(isoStringToDate(object?.terminatedOn)),
      windscreenLimit: object?.windscreenLimit,
      personalAccidentBenefit: object?.personalAccidentBenefit,
      specialPreils: object?.specialPreils,
    };
  });
};

const createOwners = payloadArray => {
  if (!Array.isArray(payloadArray)) return null;
  return payloadArray.map((object, index) => {
    return {
      ...object,
      index,
      id: object?.id,
      displaySourceId: object?.displaySourceId,
      firstName: object?.firstName,
      lastName: object?.lastName,
      primaryPhone: object?.primaryPhone,
      primaryEmail: object?.primaryEmail,
      extraDetails: {
        ownerTRN: object?.ownerTRN,
        email: object?.email,
        ownerMobileNumber: object?.ownerMobileNumber,
        addressLine1: object?.address?.addressLine1,
        addressLine2: object?.address?.addressLine2,
        city: object?.address?.city,
        ownerCountryName: object?.ownerCountryName,
      },
    };
  });
};

const createBeneficiaries = payloadArray => {
  if (!Array.isArray(payloadArray)) return null;
  return payloadArray.map((object, index) => {
    return {
      ...object,
      firstName: object?.firstName,
      lastName: object?.lastName,
      extraDetails: {
        type: object?.type,
        relationship: object?.relationship,
        trustees: object?.trustees?.map(trustee => ({
          firstName: `${trustee?.firstName}`,
          lastName: `${trustee?.lastName}`,
        })),
      },
      trustees: object?.trustees?.map(trustee => ({
        fullName: `${trustee?.firstName} ${trustee?.lastName}`,
      })),
    };
  });
};

const createOtherDetails = object => {
  if (typeof object !== 'object') {
    return null;
  }

  return {
    advisorName: object?.advisorName,
    advisorBranch: object?.advisorBranch,
    advisorBranchPhone: object?.advisorBranchPhone,
  };
};

const createMembers = payloadArray => {
  if (!Array.isArray(payloadArray)) return null;
  return payloadArray.map((object, index) => {
    return {
      ...object,
      index,
      id: object?.id,
      firstName: object?.firstName,
      lastName: object?.lastName,
      relationship: object?.relationship,
      dependentOf: object?.dependentOf,
    };
  });
};

const createDrivers = payloadArray => {
  if (!Array.isArray(payloadArray)) return null;
  return payloadArray.map((object, index) => {
    return {
      ...object,
      index,
      id: object?.id,
      firstName: object?.firstName,
      lastName: object?.lastName,
      driverType: object?.driverType,
    };
  });
};

const createInsured = payloadArray => {
  if (!Array.isArray(payloadArray)) return null;
  return payloadArray.map((object, index) => {
    return {
      ...object,
      index,
      id: object?.id,
      displaySourceId: object?.displaySourceId,
      firstName: object?.firstName,
      lastName: object?.lastName,
      primaryPhone: object?.primaryPhone,
      address: object?.address,
      extraDetails: {
        insuredDOB: object?.dob,
        ageAdmitted: object?.ageAdmitted,
      },
    };
  });
};

const createPayors = payloadArray => {
  if (!Array.isArray(payloadArray)) return null;
  return payloadArray.map((object, index) => {
    return {
      ...object,
      index,
      id: object?.id,
      displaySourceId: object?.displaySourceId,
      firstName: object?.firstName,
      lastName: object?.lastName,
      primaryPhone: object?.primaryPhone,
      companyName: object?.companyName,
    };
  });
};

const createPolicyDocs = payloadArray => {
  if (!Array.isArray(payloadArray)) return null;
  const newPayloadArray = keySort(payloadArray, 'policyDate', true);
  return newPayloadArray.map((object, index) => {
    return {
      ...object,
      index,
      policyDate: object?.policyDate,
      policyNumber: object?.policyNumber,
      policyDesc: object?.policyDesc,
      policyURL: object?.policyURL,
    };
  });
};

/*
const sampleTitleNames = [
  {
    lob: 'life',
    title: 'Express Life',
  },
  {
    lob: 'health',
    title: 'Health Provisor',
  },
  {
    lob: 'motor',
    title: 'Motor Guard',
  },
  {
    lob: 'pensions',
createOwners  },
  {
    lob: 'home',
    title: 'Home Quantum',
  },
  {
    lob: 'investments',
    title: 'Investment Quantum',
  },
];
*/

const updateCollection = (collections, policy) => {
  let collectionIndex = collections.findIndex(
    object => object.slimTitle === policy?.slimTitle,
  );
  if (collectionIndex < 0) {
    const collection = {
      id: uuidv4(),
      name: policy?.title,
      lob: policy?.lob,
      policies: [],
      slimTitle: policy?.slimTitle,
      filterLob: policy?.lob,
    };
    collectionIndex = collections.push(collection);
    collectionIndex -= 1;
    // // console.log('collectionIndex: ', collectionIndex);
  }
  // // console.log('collections: ', collections);
  // // console.log('collections[collectionIndex]: ', collections[collectionIndex]);
  const index = collections[collectionIndex].policies.findIndex(
    object => object.id === policy?.id,
  );
  if (index > -1) {
    collections[collectionIndex].policies.splice(index, 1, policy);
  } else {
    collections[collectionIndex].policies.push(policy);
  }

  return policy;
};

/*
const convertStatusToNumber = status => {
  if (status?.toLowerCase() === 'active') {
    return 100;
  }
  if (status?.toLowerCase() === 'renew') {
    return 70;
  }
  if (status?.toLowerCase() === 'review') {
    return 50;
  }
  if (status?.toLowerCase() === 'inactive') {
    return 20;
  }

  return 5;
};
*/

const formatMaturedDetails = details => {
  if (!details) return details;
  const newDetails = { ...details };

  if (newDetails.lastPensionDate) {
    newDetails.lastPensionDate = dateToString(
      isoStringToDate(newDetails.lastPensionDate),
    );
  }

  return newDetails;
};

const getInsuredItemsString = (insuredItems, lob) => {
  let insuredItemsString = null;
  if (insuredItems && insuredItems?.length > 0) {
    if (lob === homeLob) {
      const items = insuredItems?.map(item => item.address);
      if (items && items.length > 0) {
        insuredItemsString = uniq(items)
          ?.map(function(item) {
            return item;
          })
          .join(' | ');
      }
    } else if (lob === motorLob) {
      const items = insuredItems?.map(
        item => item?.general?.registrationNumber || item?.vehicleModel,
      );
      if (items && items.length > 0) {
        insuredItemsString = uniq(items)
          ?.map(function(item) {
            return item;
          })
          .join(' | ');
      }
    }
  }
  return insuredItemsString;
};

const addFormattedPremiumToPolicy = newPolicy => {
  const {
    lob,
    isFirstPremium,
    modalPremium,
    suspenseAmount,
    currency,
    digitalSales,
    planType,
    premiumsDue,
    buttonDisabled,
    buttonName,
    payOutstandingPremiumButtonDisabled,
    debtors,
    totalOutstandingPremiumsDue,
    formattedMode,
  } = newPolicy;

  let glocPremiumAmount = null;
  let glocFirstPremiumAmount = null;
  const actionCard = {};

  if (lob === healthLob || lob === pensionLob || lob === lifeLob) {
    if (isFirstPremium && modalPremium) {
      const amountDue =
        modalPremium - suspenseAmount < 0 ? 0 : modalPremium - suspenseAmount;
      const { dataValue: finalAmountDue } = formatDataTableValue({
        isNumeric: !Number.isNaN(amountDue),
        isCurrency: true,
        isLocalize: !Number.isNaN(amountDue),
        inputValue: amountDue,
        units: currency,
        showDataUnits: true,
      });
      const amountLabel = 'First Premium Due';
      actionCard.amountLabel = amountLabel;
      actionCard.amountDue = finalAmountDue;
      glocFirstPremiumAmount = finalAmountDue;
    } else if (!digitalSales && !(planType === groupPlanType)) {
      const { dataValue: finalPremiumsDue } = formatDataTableValue({
        isNumeric: !Number.isNaN(premiumsDue),
        inputValue: premiumsDue,
      });

      actionCard.premiumsDue = finalPremiumsDue;
      actionCard.status =
        premiumsDue > 0 ? `${premiumsDue} Premium${premiumsDue > 1 ? 's' : ''} Due` : ''; // All Premiums Paid
      actionCard.statusIcon = premiumsDue > 0 ? 'done' : 'warning';

      if (buttonDisabled && premiumsDue > 0) {
        if (buttonName) {
          actionCard.secondaryStatus = buttonName;
        }
      }

      const amountDue = modalPremium;
      const { dataValue: finalAmountDue } = formatDataTableValue({
        isNumeric: !Number.isNaN(amountDue),
        isCurrency: true,
        isLocalize: !Number.isNaN(amountDue),
        inputValue: amountDue,
        units: currency,
        showDataUnits: true,
      });

      glocPremiumAmount = finalAmountDue || 0;
      newPolicy.premiumAmount = glocPremiumAmount;
      const amountLabel = `${formattedMode || ''} Premium`;
      actionCard.amountLabel = amountLabel;
      actionCard.amountDue = glocPremiumAmount;
    }
  }

  let ggilPremiumAmount = null;
  const ggilOSAmount = null;

  if (lob === motorLob || lob === homeLob || lob === otherLob) {
    if (
      !payOutstandingPremiumButtonDisabled &&
      debtors &&
      debtors.length > 0 &&
      totalOutstandingPremiumsDue > 0
    ) {
      const outstandingPremiumLabel = 'O/S Amount';
      actionCard.amountLabel = outstandingPremiumLabel;

      const calculatedAmount = totalOutstandingPremiumsDue;
      const { dataValue: finalAmountDue } = formatDataTableValue({
        isNumeric: !Number.isNaN(calculatedAmount),
        isCurrency: true,
        isLocalize: !Number.isNaN(calculatedAmount),
        inputValue: calculatedAmount,
        units: currency,
        showDataUnits: true,
      });
      actionCard.amountDue = finalAmountDue;
    }

    const premiumLabel = 'Annual Premium';
    actionCard.amountLabel = premiumLabel;

    const calculatedAmount = modalPremium; // Math.round((basePremium + premiumCharge) * 100) / 100;
    const { dataValue: finalAmountDue } = formatDataTableValue({
      isNumeric: !Number.isNaN(calculatedAmount),
      isCurrency: true,
      isLocalize: !Number.isNaN(calculatedAmount),
      inputValue: calculatedAmount,
      units: currency,
      showDataUnits: true,
    });
    actionCard.amountDue = finalAmountDue;
    ggilPremiumAmount = finalAmountDue;
    newPolicy.premiumAmount = ggilPremiumAmount;
    if (buttonDisabled) {
      if (buttonName) {
        actionCard.secondaryStatus = buttonName;
      }
    }
  }

  newPolicy.ggilPremiumAmount = ggilPremiumAmount;
  newPolicy.ggilOSAmount = ggilOSAmount;
  newPolicy.glocPremiumAmount = glocPremiumAmount;
  newPolicy.glocFirstPremiumAmount = glocFirstPremiumAmount;
  newPolicy.actionCard = actionCard;
  return newPolicy;
};

export const createPolicies = (payloadArray, lobTypes, templateName = '') => {
  if (!Array.isArray(payloadArray)) return null;
  // console.log('payloadArray[0]: ', payloadArray[0]);

  const newPolicies = payloadArray.map((policy, index) => {
    const {
      type,
      masterId,
      title,
      payToDate,
      dueDate,
      expiryDate,
      issueDate,
      inceptionDate,
      insured,
      dob,
      ageAdmitted,
      members,
      policyDocs,
      paymentMode,
      mode,
      insuredItems,
      planType,
      contractCategory,
      id,
      cardNumber,
      customerId,
      clientKey,
      displayId,
      status,
      paymentMethod,
      cashValue,
      name,
      canRenew,
      claimCount,
      lastPaidAmount,
      memberCount,
      coverages,
      otherDetails,
      beneficiary,
      subjectMatters,
      insuredItemDetails,
      addInsured,
      secondaryStatus,
      buttonName,
      buttonDisabled,
      planId,
      ceaseDate,
      renewalDate,
      originalInceptionDate,
      fromDate,
      producer,
      agent,
      agentType,
      allowRenewals,
      agentNumber,
      annualPremium,
      amount,
      premiumTax,
      premiumAmount,
      premiumChg,
      basePremium,
      loanAmount,
      modalPremium,
      renewalPremium,
      suspenseAmount,
      paidAmount,
      premiumsDue,
      isFirstPremium,
      currency,
      policyDocuments,
      payor,
      discounts,
      drivers,
      owner,
      maturedDetails,
      hasMatured,
      digitalSales,
      autoPay,
    } = policy || {};

    const lobObj = lobTypes?.find(
      lobObject =>
        type?.toLowerCase() === lobObject?.lob ||
        masterId?.toLowerCase().indexOf(lobObject?.lob) > -1,
    );

    if (type === investmentLob && policy) {
      return createAccountsPayload([policy], lobTypes)[0];
    }

    let filterDate = null;
    let filterDateType = '';
    const isDutchRegion = templateName === dutchTemplate;
    if (payToDate) {
      filterDate = dateToString(isoStringToDate(payToDate));
      filterDateType = 'payTo';
    } else if (dueDate) {
      filterDate = dateToString(isoStringToDate(dueDate));
      filterDateType = 'due';
    } else if (expiryDate) {
      filterDate = dateToString(isoStringToDate(expiryDate));
      filterDateType = 'expiry';
    } else if (issueDate) {
      filterDate = dateToString(isoStringToDate(issueDate));
      filterDateType = 'issue';
    }
    const formattedInceptionDate = dateToString(isoStringToDate(inceptionDate));
    const formattedExpiryDate = dateToString(isoStringToDate(expiryDate));
    const insuredArray = insured;
    const insuredPayloadArray = insuredArray?.map(obj => ({
      ...obj,
      ageAdmitted,
      insuredDOB: dob,
    }));

    let newPlanType = '';
    if (type === healthLob || type === lifeLob || type === pensionLob) {
      newPlanType = planType?.toLowerCase() || 'individual';
    }
    if (type === motorLob || type === homeLob || type === otherLob) {
      newPlanType = contractCategory;
    }

    const slug =
      title &&
      slugify(title, {
        lower: true, // Convert to lowercase
        strict: true, // Remove special characters
      });

    const owners = policy?.owners || policy?.owner;

    const insuredMember = members?.find(mem => {
      const isSelf = mem?.relationship?.toLowerCase() === 'self';
      return isSelf;
    });

    const insuredMemberName =
      insuredMember &&
      `${insuredMember?.firstName || ''} ${insuredMember?.lastName || ''}`;
    const insuredPersonName =
      insured &&
      insured.length > 0 &&
      `${insured?.[0].firstName || ''} ${insured?.[0].lastName || ''}`;

    const ownerPersonName =
      owners &&
      owners.length > 0 &&
      `${owners?.[0].firstName || ''} ${owners?.[0].lastName || ''}`;

    const insuredName = insuredPersonName || insuredMemberName || ownerPersonName;
    let dueDateTitle = payToDate || dueDate;
    if (templateName !== dutchTemplate) {
      const convertedDueDate = stringToDate(payToDate || dueDate);
      const dateValue = convertedDueDate?.getDate();
      dueDateTitle = `${dateValue}${nth(dateValue)}`;
    }

    const newPolicyDocs = policyDocs && createPolicyDocs(policyDocs);
    const latestRenewalNotice = newPolicyDocs?.find(
      x => x?.policyDesc === 'Renewal Notice',
    );
    const latestRenewalNoticeYear = parseInt(
      latestRenewalNotice?.policyDate?.substring(0, 4),
      10,
    );
    let currentInceptionDate;
    if (templateName == dutchTemplate) {
      const [day, month, year] = inceptionDate?.split('-').map(x => Number(x));
      currentInceptionDate = new Date(year, month - 1, day);
    } else {
      currentInceptionDate = inceptionDate && isoStringToDate(inceptionDate);
    }
    const currentInceptionYear = currentInceptionDate?.getFullYear();
    const isValidRenewalNotice = latestRenewalNoticeYear > currentInceptionYear;
    const newMode = paymentMode || mode;
    const myformattedMode = formatMode(newMode);
    const myformattedModeNoun = formatModeNoun(newMode);

    const autoPayNextDate = dateToString(
      isoStringToDate(autoPay?.details?.nextPaymentAttempt),
    );

    const newPolicy = {
      ...policy,
      insuredName,
      dueDateTitle,
      insuredPersonName,
      ownerPersonName,
      insuredMemberName,
      insuredItemsString: getInsuredItemsString(insuredItems, lobObj?.lob),
      index: uuidv4(), // startIndex + index,
      id,
      policyId: id,
      accountId: id,
      idNoSpace: id?.replace(/ /g, ''),
      lob: lobObj?.lob,
      color: lobObj?.color,
      icon: lobObj?.icon,
      materialIcon: lobObj?.materialIcon,
      type: type?.toLowerCase(),
      planType: newPlanType,
      slug,
      cardNumber,
      customerId,
      clientKey,
      displayId,
      level: status, // createStatus(object?.status), // 'Active',
      mode: mode || paymentMode,
      paymentMethod,
      cashValue,
      name,
      slimTitle: removeAllSpaces(title?.toLowerCase()),
      policyTitle: title,
      title, // not available
      canRenew,
      claimCount,
      lastPaidAmount,
      memberCount,
      masterId,
      coverages: createCoverages(coverages, templateName),
      owners: createOwners(owners),
      beneficiaries: createBeneficiaries(beneficiary),
      otherDetailsData: createOtherDetails(otherDetails),
      members: createMembers(members),
      subjectMatters: createSubjectMatters(subjectMatters),
      insuredItems: createInsuredItems(insuredItems, type, templateName),
      insuredItemDetails,
      addInsured,
      // life
      // statusNumber: currentStatusObject?.statusNum || 0,
      status,
      secondaryStatus,
      buttonName,
      buttonDisabled,
      canRenewStatus: !buttonDisabled,
      canRenewStatusNumber: !buttonDisabled ? 1 : 0,
      planId,
      filterStatus: secondaryStatus || status,
      filterLob: lobObj?.lob,
      filterDate,
      filterDateType,
      issueDate: dateToString(isoStringToDate(issueDate)),
      payToDate: dateToString(isoStringToDate(payToDate)),
      ceaseDate: dateToString(isoStringToDate(ceaseDate)),
      renewalDate: dateToString(isoStringToDate(renewalDate)),
      inceptionDate: formattedInceptionDate,
      originalInceptionDate: dateToString(isoStringToDate(originalInceptionDate)),
      expiryDate: formattedExpiryDate,
      dueDate: dateToString(isoStringToDate(dueDate)),
      periodOfCover:
        formattedInceptionDate &&
        formattedExpiryDate &&
        `${formattedInceptionDate} to ${formattedExpiryDate}`,
      fromDate: dateToString(isoStringToDate(fromDate)),
      producer: producer || '',
      agent,
      agentType,
      allowRenewals,
      agentNumber,
      annualPremium,
      amount: parseFloat(amount),
      premiumTax,
      premium: premiumAmount,
      premiumCharge: premiumChg,
      basePremium,
      loanAmount,
      modalPremium:
        parseFloat(modalPremium) ||
        (type === healthLob &&
          coverages &&
          coverages?.length === 1 &&
          coverages[0].premiumAmount),
      renewalPremium: parseFloat(renewalPremium),
      premiumsDue,
      paidAmount,
      suspenseAmount,
      isFirstPremium,
      currency,
      filterCurrency: currency,
      insured: createInsured(insuredPayloadArray),
      insuredNameNoSpace: insuredName?.replace(/ /g, ''),
      policyDocuments,
      policyDocs,
      latestRenewalNotice: isValidRenewalNotice && latestRenewalNotice,
      payor: createPayors(payor),
      discounts: createDiscounts(discounts),
      drivers: createDrivers(drivers),
      clientPolicyId: owner && owner[0] ? owner[0]?.id?.split('-')[1] : null,
      digitalSales,
      hasMatured,
      maturedDetails: formatMaturedDetails(maturedDetails),
      isAutoPayNotEligible: !autoPay?.availableForSetup,
      isAutoPayEligible: autoPay?.availableForSetup,
      autoPayEnabled: !!autoPay?.details?._id,
      autoPayNextDate,
      autoPayNextReadableDate: dateToReadableString(
        isoStringToDate(autoPay?.details?.nextPaymentAttempt),
      ),
      isAutoPayActive: autoPay?.details?.status === 'active',
      autoPayStatus: autoPay?.details?.status && toTitleCase(autoPay?.details?.status),
      nextPremiumDueDate: dateToReadableString(stringToDate(payToDate || dueDate)),
      formattedMode: myformattedMode,
      lowerCaseFormattedMode: myformattedMode?.toLowerCase(),
      formattedModeNOun: myformattedModeNoun,
      lowerCaseFormattedModeNoun: myformattedModeNoun?.toLowerCase(),
      isAutoPayInCurrentMonth: autoPayNextDate ? isCurrentMonth(autoPayNextDate) : false,
      nextPremiumDueOnSince:
        (stringToDate(payToDate || dueDate) || new Date())?.getTime() <
        new Date()?.getTime()
          ? 'was due since'
          : 'is due on',
      // ...searchWords,
    };

    const formatPolicy = addFormattedPremiumToPolicy(newPolicy);

    // const searchWords = generateSearchWords(object);
    // const newKeys = getKeys(searchWords);
    // searchKeys = [...searchKeys, ...newKeys];
    // searchKeys = uniq(searchKeys);
    // // console.log('searchWords: ', searchWords);

    // const reduceObj = reduceKeys(obj);

    // // console.log('searchKeys: ', searchKeys);
    /// / console.log(policy);

    return formatPolicy;
  });

  /// / console.log(newPolicies);

  return newPolicies;
};

export const createCustomPolicies = (payloadArray, lobTypes) => {
  if (!Array.isArray(payloadArray)) return null;

  const newPolicies = payloadArray.map((object, index) => {
    const lobObj = lobTypes?.find(
      lobObject => object?.policyType?.toLowerCase() === lobObject?.lob,
    );

    let subPolicy = {};
    if (object?.policy) {
      subPolicy = createPolicies([object?.policy], lobTypes)[0];
    }
    const policy = {
      ...object,
      index: uuidv4(),
      lob: lobObj?.lob,
      color: lobObj?.color,
      icon: lobObj?.icon,
      materialIcon: lobObj?.materialIcon,
      isCustomNotAvailable: !object?.isCustomPayAvailable,
      isCustomNotEligible: !object?.isCustomPayEligible,
      customTypeLabel: object?.isFullyCustom
        ? 'Previously Used Policies'
        : 'Your Portfolio Policies',
      customType: object?.isFullyCustom ? 'custom' : 'portfolio',
      policy: subPolicy,
      insuredItemsString: subPolicy?.insuredItemsString || object?.insuredItems,
    };

    return policy;
  });

  return newPolicies;
};

const createPayload = (payloadArray, lobTypes, templateName = '') => {
  if (!Array.isArray(payloadArray)) return null;
  searchKeys = [];
  const newPolicies = createPolicies(payloadArray, lobTypes, templateName);
  // const collections = [];
  newPolicies.map((policy, index) => {
    const newKeys = getKeys(policy);
    searchKeys = [...searchKeys, ...newKeys];
    searchKeys = uniq(searchKeys);
    return policy; // updateCollection(collections, policy);
  });

  /*
  newPolicies.sort((a, b) => {
    if (a.canRenewStatusNumber === b.canRenewStatusNumber) {
      // Price is only important when cities are the same
      return b.statusNumber - a.statusNumber ? 1 : -1;
    }
    return b.canRenewStatusNumber > a.canRenewStatusNumber ? 1 : -1;
  });
  */

  return newPolicies;
};

export const getSampleResponse = (lob, action) => {
  // console.log('lob: ', lob);
  // // console.log('params: ', params);
  if (lob === investmentLob) {
    return action?.payload;
  }
  const initialPayloadData = JSON.parse(JSON.stringify(payloadData));
  // console.log('initialPayloadData: ', initialPayloadData);
  if (lob && initialPayloadData.payload.result) {
    const filteredPolicies = initialPayloadData?.payload?.result?.data?.filter(
      x => x?.type === lob,
    );
    // console.log('filteredPolicies: ', filteredPolicies);

    const newPayloadData = { ...initialPayloadData };
    newPayloadData.payload.result.data = filteredPolicies;
    newPayloadData.payload.result.total = filteredPolicies?.length;
    // console.log('newPayloadData: ', newPayloadData);
    return newPayloadData;
  }
  return action?.payload;
};

const insuranceReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_INSURANCES_API_REQUEST:
    case GET_INSURANCE_INVESTMENTS_REQUEST: {
      const body = action?.body;
      // console.log('Response body is ', body);
      const isPending = state.isPending ? state.isPending : {};
      const isSuccess = state.isSuccess ? state.isSuccess : {};
      const isError = state.isError ? state.isError : {};

      if (body) {
        const storeKey = `${body.staticLob}_${body.alpha2}`;
        return {
          ...state,
          isPending: {
            ...isPending,
            [body.staticLob]: true,
            [storeKey]: true,
          },
          isSuccess: {
            ...isSuccess,
            [body.staticLob]: false,
            [storeKey]: false,
          },
          isError: {
            ...isError,
            [body.staticLob]: false,
            [storeKey]: false,
          },
        };
      }
      break;
    }

    case GET_INSURANCES_API_SUCCESS:
    case GET_INSURANCE_INVESTMENTS_SUCCESS: {
      // const startIndex = state?.insurances?.length;
      const lobTypes = action?.lobTypes;
      const body = action?.body;
      const response = action?.payload; // getSampleResponse(body?.staticLob, action); //
      const { templateName } = action;
      if (response === 'forceReload') {
        return {
          ...state,
          insurances: [...state.insurances],
          nextItemCount: body?.nextItemCount,
          nextScrollCount: body?.nextScrollCount,
          nextStartIndex: body?.nextStartIndex,
        };
      }

      const cacheKey = action?.cacheKey;
      // console.log('response 2: ', response);
      let insurancePayload = [];
      let newTotal = 0;

      // console.log('response: ', response);
      insurancePayload = createPayload(
        response?.payload?.result?.data,
        lobTypes,
        templateName,
      );

      newTotal = response?.payload?.result?.total || 0;

      const insurances = insurancePayload;

      // console.log('success body: ', body);

      // console.log('insurances: ', insurances);
      // const insuranceCollections = collections;
      const nowTime = new Date().getTime();

      const incomingInsurances =
        insurances && Array.isArray(insurances) ? insurances : [];
      const oldInsurances =
        state?.insurances && Array.isArray(state?.insurances) ? state?.insurances : [];

      const existingInsurances = [...oldInsurances];
      // console.log('oldInsurances: ', oldInsurances);
      incomingInsurances.map(incomingInsurance => {
        // console.log('incomingInsurance: ', incomingInsurance);
        incomingInsurance.body = body;
        let index = 0;
        if (incomingInsurance.body.infiniteScroll) {
          index = existingInsurances.findIndex(
            oldInsurance =>
              oldInsurance.id === incomingInsurance.id &&
              oldInsurance.body.infiniteScroll ===
                incomingInsurance.body.infiniteScroll &&
              oldInsurance.body.alpha2 === incomingInsurance.body.alpha2 &&
              oldInsurance.body.isMobile === incomingInsurance.body.isMobile,
          );
        } else {
          index = existingInsurances.findIndex(
            oldInsurance =>
              oldInsurance.id === incomingInsurance.id &&
              oldInsurance.body.infiniteScroll ===
                incomingInsurance.body.infiniteScroll &&
              oldInsurance.body.offset === incomingInsurance.body.offset &&
              oldInsurance.body.limit === incomingInsurance.body.limit &&
              oldInsurance.body.alpha2 === incomingInsurance.body.alpha2 &&
              oldInsurance.body.isMobile === incomingInsurance.body.isMobile,
          );
        }
        // Replace item at index using native splice
        if (index !== -1) {
          existingInsurances.splice(index, 1, incomingInsurance);
        } else {
          existingInsurances.push(incomingInsurance);
        }
        return null;
      });
      // console.log('existingInsurances: ', existingInsurances);
      const isPending = state.isPending ? state.isPending : {};
      const isSuccess = state.isSuccess ? state.isSuccess : {};
      const isError = state.isError ? state.isError : {};
      const tempTotal = state.total ? state.total : {};
      // console.log('total: ', tempTotal);
      // console.log('newTotal: ', newTotal);
      // console.log('body: ', body);
      // console.log('response: ', response);
      const storeKey = `${body.staticLob}_${body.alpha2}`;

      const newTotalState = {
        ...tempTotal,
        [body.staticLob]: newTotal,
        [storeKey]: newTotal,
        [cacheKey]: newTotal,
      };

      const newError = state.error ? { ...state.error } : {};

      const filteredAccounts = existingInsurances?.filter(x => x.type === investmentLob);

      return {
        ...state,
        isPending: {
          ...isPending,
          [body.staticLob]: false,
          [storeKey]: false,
        },
        isError: {
          ...isError,
          [body.staticLob]: false,
          [storeKey]: false,
        },
        isSuccess: {
          ...isSuccess,
          [body.staticLob]: true,
          [storeKey]: true,
        },
        error: {
          ...newError,
        },
        total: newTotalState,
        response, // newPortfolio,
        searchKeys,
        insurances: existingInsurances,
        accounts: filteredAccounts,
        // insuranceCollections,
        [persistKey]: nowTime,
        firstTime: false,
        nextItemCount: body?.nextItemCount,
        nextScrollCount: body?.nextScrollCount,
        nextStartIndex: body?.nextStartIndex,
        currentCacheKey: cacheKey,
        [DEFAULT_CACHE_KEY]: {
          ...(state[DEFAULT_CACHE_KEY] || {}),
          [cacheKey]: {
            time: generateCacheTTL(),
            payload: null,
          },
        },
      };
    }
    case GET_INSURANCES_API_ERROR:
    case GET_INSURANCE_INVESTMENTS_ERROR: {
      const body = action?.body;

      const isPending = state.isPending ? state.isPending : {};
      const isSuccess = state.isSuccess ? state.isSuccess : {};
      const isError = state.isError ? state.isError : {};
      const error = state.error ? state.error : {};

      const storeKey = `${body.staticLob}_${body.alpha2}`;

      return {
        ...state,
        isPending: {
          ...isPending,
          [body.staticLob]: false,
          [storeKey]: false,
        },
        isError: {
          ...isError,
          [body.staticLob]: true,
          [storeKey]: true,
        },
        isSuccess: {
          ...isSuccess,
          [body.staticLob]: false,
          [storeKey]: false,
        },
        error: {
          ...error,
          [body.staticLob]: action?.payload,
          [storeKey]: action?.payload,
        },
        firstTime: false,
      };
    }
    case RENEW_POLICY_API_SUCCESS:
      return {
        ...state,
        [persistKey]: null,
        [DEFAULT_CACHE_KEY]: null,
        insurances: initialState.insurances,
        accounts: [],
        insurancePolicy: null,
      };
    // case RENEW_POLICY_HOME_API_SUCCESS:
    //   return {
    //     ...state,
    //     [persistKey]: null,
    //   };
    case ADD_RENEWAL_TO_CART_API_SUCCESS:
      return {
        ...state,
        [persistKey]: null,
        insurances: initialState.insurances,
        accounts: [],
        insurancePolicy: null,
      };
    case AMEND_POLICY_API_SUCCESS:
      return {
        ...state,
        [persistKey]: null,
        [DEFAULT_CACHE_KEY]: null,
        insurances: initialState.insurances,
        accounts: [],
        insurancePolicy: null,
      };
    case PAY_NOW_API_SUCCESS:
    case DELETE_RECURRING_PAYMENT_SUCCESS:
    case PAUSE_RECURRING_PAYMENT_SUCCESS:
    case ACTIVATE_RECURRING_PAYMENT_SUCCESS:
      return {
        ...state,
        [persistKey]: null,
        [DEFAULT_CACHE_KEY]: null,
        insurances: initialState.insurances,
        accounts: [],
        insurancePolicy: null,
      };

    case PAY_FIRST_PREMIUM_API_SUCCESS:
      return {
        ...state,
        [persistKey]: null,
        [DEFAULT_CACHE_KEY]: null,
        insurances: initialState.insurances,
        accounts: [],
        insurancePolicy: null,
      };

    case MAKE_FAC_PAYMENT_API_SUCCESS:
      return {
        ...state,
        [persistKey]: null,
        [DEFAULT_CACHE_KEY]: null,
        insurances: initialState.insurances,
        accounts: [],
        insurancePolicy: null,
      };

    case SELECT_CLIENTS_SUCCESS: {
      return {
        ...state,
        [persistKey]: null,
        [DEFAULT_CACHE_KEY]: null,
        insurances: initialState.insurances,
        accounts: [],
        insurancePolicy: null,
      };
    }
    case CLEAR_PORTFOLIO_CACHE:
      return {
        ...state,
        [persistKey]: null,
        [DEFAULT_CACHE_KEY]: null,
        insurances: initialState.insurances,
        accounts: [],
        insurancePolicy: null,
      };

    case GET_NEXT_INSURANCES_SCROLL_SUCCESS: {
      const nextStartIndex = action?.payload?.nextStartIndex;
      const nextScrollCount = action?.payload?.nextScrollCount;
      const nextItemCount = action?.payload?.nextItemCount;
      const scrollKey = action?.payload?.scrollKey;

      return {
        ...state,

        nextItemCount,
        nextScrollCount,
        nextStartIndex,
        scrollKey,
      };
    }

    case RESET_INFINITE_SCROLL_SUCCESS: {
      return {
        ...state,

        nextItemCount: 0,
        nextScrollCount: 0,
        nextStartIndex: 0,
        scrollKey: null,
      };
    }
    case SIGNIN_API_SUCCESS: {
      return {
        ...state,
        [persistKey]: null,
        insurances: [],
        firstTime: true,
      };
    }
    case GET_MATURED_PENSION_API_REQUEST:
      return {
        ...state,
        isMaturedPending: true,
        isMaturedError: false,
        isMaturedSuccess: false,
      };
    case GET_MATURED_PENSION_API_SUCCESS: {
      const response = action?.payload;
      const maturedPensionPayload = response.payload;
      const maturedPension = createMaturedPension(maturedPensionPayload);

      return {
        ...state,
        isMaturedPending: false,
        isMaturedError: false,
        isMaturedSuccess: true,
        response,
        maturedPension,
      };
    }
    case GET_MATURED_PENSION_API_ERROR:
      return {
        ...state,
        isMaturedPending: false,
        isMaturedError: true,
        isMaturedSuccess: false,
        error: action?.payload,
      };
    case RESET_INSURANCE: {
      const lob = action.payload;
      let newInsurances = [];
      let newPersistKey = null;
      if (lob) {
        newInsurances = state?.insurances?.filter(
          oldInsurance => oldInsurance.filterLob !== lob,
        );
        newPersistKey = state[persistKey];
      }
      return {
        ...state,
        [persistKey]: newPersistKey,
        [DEFAULT_CACHE_KEY]: null,
        insurances: newInsurances,
        accounts: [],
        insurancePolicy: null,
      };
    }
    case GET_INSURANCE_FILTER_SETTINGS_API_REQUEST:
      return {
        ...state,
        isGetInsuranceFilterSettingsPending: true,
        isGetInsuranceFilterSettingsError: false,
        isGetInsuranceFilterSettingsSuccess: false,
      };
    case GET_INSURANCE_FILTER_SETTINGS_API_SUCCESS: {
      const settings = action?.payload?.payload || null;

      const response = action?.payload;
      const cacheKey = action?.cacheKey;
      return {
        ...state,
        // searchKeys,
        isGetInsuranceFilterSettingsPending: false,
        isGetInsuranceFilterSettingsError: false,
        isGetInsuranceFilterSettingsSuccess: true,
        insuranceFilterSettingsResponse: response,
        insuranceFilterSettings: settings,
        currentFilterCacheKey: cacheKey,
        [DEFAULT_CACHE_KEY]: {
          ...(state[DEFAULT_CACHE_KEY] || {}),
          [cacheKey]: {
            time: generateCacheTTL(),
            payload: response,
          },
        },
      };
    }
    case GET_INSURANCE_FILTER_SETTINGS_API_ERROR:
      return {
        ...state,
        isGetInsuranceFilterSettingsPending: false,
        isGetInsuranceFilterSettingsError: true,
        isGetInsuranceFilterSettingsSuccess: false,
        insuranceFilterSettingsError: action.payload,
      };

    case GET_SINGLE_INSURANCE_API_REQUEST:
      return {
        ...state,
        isSingleInsurancePolicyPending: true,
        isSingleInsurancePolicyError: false,
        isSingleInsurancePolicySuccess: false,
      };
    case GET_SINGLE_INSURANCE_API_SUCCESS: {
      const tempArr = [];
      const lobTypes = action?.lobTypes;
      const insurancePolicy = action?.payload?.payload;
      const templateName = action?.templateName;
      const dontFormat = action?.dontFormat;
      // console.log('insurancePolicy: ', insurancePolicy);
      // console.log('insurancePolicy: ', insurancePolicy);
      tempArr.push(insurancePolicy);
      const newPolicies = dontFormat
        ? tempArr
        : createPayload(tempArr, lobTypes, templateName);
      // console.log('newPolicies: ', newPolicies);
      // // console.log(renewalPolicy);
      // // console.log(tempArr);
      const cacheKey = action?.cacheKey;
      const response = action?.payload;
      // response.payload = null;
      return {
        ...state,
        isSingleInsurancePolicyPending: false,
        isSingleInsurancePolicyError: false,
        isSingleInsurancePolicySuccess: true,
        singleInsurancePolicyResponse: response,
        insurancePolicy: newPolicies[0],
        [DEFAULT_CACHE_KEY]: {
          ...(state[DEFAULT_CACHE_KEY] || {}),
          [cacheKey]: {
            time: generateCacheTTL(),
            payload: response,
          },
        },
      };
    }
    case GET_SINGLE_INSURANCE_API_ERROR:
      return {
        ...state,
        isSingleInsurancePolicyPending: false,
        isSingleInsurancePolicyError: true,
        isSingleInsurancePolicySuccess: false,
        singleInsurancePolicyError: action?.payload,
      };

    case GET_POLICY_DOCUMENTS_API_REQUEST:
      return {
        ...state,
        isPolicyDocumentsPending: true,
        isPolicyDocumentsError: false,
        isPolicyDocumentsSuccess: false,
      };

    case GET_POLICY_DOCUMENTS_API_SUCCESS: {
      const response = action?.payload?.payload;

      return {
        ...state,
        isPolicyDocumentsPending: false,
        isPolicyDocumentsError: false,
        isPolicyDocumentsSuccess: true,
        policyDocuments: response?.files,
      };
    }

    case GET_POLICY_DOCUMENTS_API_ERROR:
      return {
        ...state,
        isPolicyDocumentsPending: false,
        isPolicyDocumentsError: true,
        isPolicyDocumentsSuccess: false,
      };

    case DOWNLOAD_POLICY_DOCUMENT_API_REQUEST:
      return {
        ...state,
        isDownloadPolicyDocumentPending: true,
        isDownloadPolicyDocumentError: false,
        isDownloadPolicyDocumentSuccess: false,
      };

    case DOWNLOAD_POLICY_DOCUMENT_API_SUCCESS: {
      const response = action?.payload?.payload;

      return {
        ...state,
        isDownloadPolicyDocumentPending: false,
        isDownloadPolicyDocumentError: false,
        isDownloadPolicyDocumentSuccess: true,
        downloadPolicyDocument: response,
      };
    }

    case DOWNLOAD_POLICY_DOCUMENT_API_ERROR:
      return {
        ...state,
        isDownloadPolicyDocumentPending: false,
        isDownloadPolicyDocumentError: true,
        isDownloadPolicyDocumentSuccess: false,
      };

    case UPDATE_INSURANCE_BODY_SUCCESS: {
      const body = action?.body;
      const cacheFilterParams = state.cacheFilterParams ? state.cacheFilterParams : {};
      const storeKey = `${body.staticLob}_${body.alpha2}`;

      return {
        ...state,
        cacheFilterParams: {
          ...cacheFilterParams,
          [storeKey]: {
            itemCount: body?.limit,
            startIndex: body?.offset,
            scrollCount: body?.limit,
          },
        },
      };
    }

    case GET_POLICY_EXTERNAL_LINKS_API_REQUEST:
      return {
        ...state,
        isPolicyExternalLinksPending: true,
        isPolicyExternalLinksError: false,
        isPolicyExternalLinksSuccess: false,
      };

    case GET_POLICY_EXTERNAL_LINKS_API_SUCCESS: {
      const response = action?.payload?.payload;

      return {
        ...state,
        isPolicyExternalLinksPending: false,
        isPolicyExternalLinksError: false,
        isPolicyExternalLinksSuccess: true,
        policyExternalLinks: response,
      };
    }

    case GET_POLICY_EXTERNAL_LINKS_API_ERROR:
      return {
        ...state,
        isPolicyExternalLinksPending: false,
        isPolicyExternalLinksError: true,
        isPolicyExternalLinksSuccess: false,
      };

    case RESET_CACHE_FILTER_PARAMS:
      return {
        ...state,
        cacheFilterParams: {},
      };

    default:
      return state;
  }
};
export default insuranceReducer;

/*


const modifiedPayload = payloadArray => {
  if (!Array.isArray(payloadArray)) return null;
  payloadArray.map((object, index) => {
    const lobObj = lobTypes?.find(
      lobObject => object?._id?.toLowerCase().indexOf(lobObject.lob) > 0,
    );
    const position = index;
    return {
      index: position,
      id: object._id ? `${object?._id}${position}` : uuidv4(), // `${object?._id}_${uiid}`,
      name: object?.name,
      lob: lobObj?.lob,
      policies: createPolicies(object?.policies),
    };
  });

  return collections;
};

*/
