import { LOCATION_CHANGE } from 'connected-react-router';
import {
  UPDATE_TEMP_STATE,
  RESET_TEMP_STATE,
  APP_READY_STATE,
  HIDE_BACK_BUTTON_STATE,
} from '../../constants/tempActionNames';
import { generatePaymentDates, uuidv4 } from '../../utils/functions';

const date = new Date();

const day = date?.getDate();
const month = date?.getMonth() + 1;
const year = date?.getFullYear();

const initialState = {
  dontReload: false,
  scrollKey: '',
  filterOptions: null,
  defaultSearchType: null,
  searchOptions: null,
  filterOpen: false,
  filterTitle: null,
  settingsOpen: false,
  isMobile: false,
  appReady: false,
  hideBackButton: false,
  wizardLoaded: false,
  contactsOpen: false,
  referralDetails: {},
  modalParams: null,
  availablePaymentDates: generatePaymentDates(),
  currentDay: day,
  currentMonth: month,
  currentYear: year,
  lastBrowserUrl: null,
  requestsLoaded: false,
  locationChangeUUID: null,
  showCountrySelection: false,
};

function tempReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_TEMP_STATE: {
      return {
        ...state,
        ...action?.payload,
      };
    }
    case RESET_TEMP_STATE: {
      const { referralDetails } = state;
      return { ...initialState, referralDetails };
    }

    case LOCATION_CHANGE: {
      const { referralDetails } = state;
      const isNewRoute = !state?.lastBrowserUrl?.includes(
        action?.payload?.location?.pathname,
      );

      return {
        ...initialState,
        locationChangeUUID: isNewRoute ? uuidv4() : state?.locationChangeUUID,
        reloadWizard: state.reloadWizard,
        wizardLoaded: state.wizardLoaded,
        dontReload: state.dontReload,
        appReady: state.appReady,
        settingsOpen: state.settingsOpen,
        filterOpen: state.filterOpen,
        referralDetails,
      };
    }

    case APP_READY_STATE: {
      return {
        ...state,
        appReady: true,
      };
    }

    case HIDE_BACK_BUTTON_STATE: {
      return {
        ...state,
        hideBackButton: action?.payload,
      };
    }
    default:
      return state;
  }
}

export default tempReducer;
