import { TOUR_GUIDE, TOUR_GUIDE_RUN, TOUR_GUIDE_STEPS, TOUR_GUIDE_STEP_INDEX } from "../../constants/tourGuideActionNames";

const initialState = { run: false, steps: [], stepIndex: null }
const tourGuideReducer = (state = initialState, action) => {
    switch (action.type) {
        case TOUR_GUIDE_RUN:
            return { ...state, run: action.run };
        case TOUR_GUIDE_STEPS:
            return { ...state, steps: action.steps };
        case TOUR_GUIDE_STEP_INDEX:
            return { ...state, stepIndex: action.stepIndex };
        case TOUR_GUIDE: {
            return { ...state, ...action.payload }
        }
        default:
            return state;
    }
}

const tourGuideAction = () => {
    return {
        type: TOUR_GUIDE,
        payload: {
            run: false,
            steps: [],
            stepIndex: null
        }
    }
}

export default tourGuideReducer;