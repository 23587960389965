import {
  SAVE_PREMIUM_INDICATION_ERROR,
  SAVE_PREMIUM_INDICATION_SUCCESS,
  SAVE_PREMIUM_INDICATION_REQUEST,
  GET_PREMIUM_INDICATION_SUCCESS,
  GET_PREMIUM_INDICATION_ERROR,
  GET_PREMIUM_INDICATION_REQUEST,
  DELETE_PREMIUM_INDICATION_ERROR,
  DELETE_PREMIUM_INDICATION_SUCCESS,
  DELETE_PREMIUM_INDICATION_REQUEST,
  UPDATE_PREMIUM_INDICATION_ERROR,
  UPDATE_PREMIUM_INDICATION_SUCCESS,
  UPDATE_PREMIUM_INDICATION_REQUEST,
} from '../../constants/premiumIndicationActionNames';

export const initialState = {
  // read
  isFetching: false,
  data: [],
  isFetchingError: false,
  fetchingError: null,
  // delete
  isDeleting: false,
  deletedId: null,
  isDeletionError: false,
  deletionError: null,
  // create,
  saveError: null,
  // update
  updatedPremiumId: null,
  updateError: null,
};

const premiumIndicationReducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_PREMIUM_INDICATION_SUCCESS: {
      const payload = action?.payload;
      return {
        ...state,
        isSaving: false,
        savedPremiumId: payload.savedPremiumId,
      };
    }
    case SAVE_PREMIUM_INDICATION_ERROR: {
      const payload = action?.payload;
      return {
        ...state,
        isSaving: false,
        saveError: payload,
      };
    }
    case SAVE_PREMIUM_INDICATION_REQUEST: {
      return {
        ...state,
        isSaving: true,
        saveError: null,
      };
    }
    case UPDATE_PREMIUM_INDICATION_SUCCESS: {
      const payload = action?.payload;
      return {
        ...state,
        // isSaving: false,
        updatedPremiumId: payload.storedId,
      };
    }
    case UPDATE_PREMIUM_INDICATION_ERROR: {
      const payload = action?.payload;
      return {
        ...state,
        // isSaving: false,
        saveError: payload,
      };
    }
    case UPDATE_PREMIUM_INDICATION_REQUEST: {
      return {
        ...state,
        saveError: null,
      };
    }
    case GET_PREMIUM_INDICATION_SUCCESS: {
      const payload = action?.payload;
      return {
        ...state,
        isFetching: false,
        data: payload,
      };
    }
    case GET_PREMIUM_INDICATION_ERROR: {
      const payload = action?.payload;
      return {
        ...state,
        isFetching: false,
        isFetchingError: true,
        fetchingError: payload,
      };
    }
    case GET_PREMIUM_INDICATION_REQUEST: {
      // const payload = action?.payload;
      return {
        ...state,
        isFetchingError: false,
        isFetching: true,
      };
    }
    case DELETE_PREMIUM_INDICATION_SUCCESS: {
      const payload = action?.payload;
      return {
        ...state,
        isDeleting: false,
        deletedId: payload?.storedId,
      };
    }
    case DELETE_PREMIUM_INDICATION_ERROR: {
      const payload = action?.payload;
      return {
        ...state,
        isDeleting: false,
        isDeletionError: true,
        deletionError: payload,
      };
    }
    case DELETE_PREMIUM_INDICATION_REQUEST: {
      // const payload = action?.payload;
      return {
        ...state,
        isDeleting: true,
        deletionError: null,
      };
    }

    default:
      return {
        ...state,
      };
  }
};

export default premiumIndicationReducer;
