import {
  GET_PENDING_REQUEST_HISTORY_DETAILS_API_REQUEST,
  GET_PENDING_REQUEST_HISTORY_DETAILS_API_SUCCESS,
  GET_PENDING_REQUEST_HISTORY_DETAILS_API_ERROR,
} from '../../../constants/requestActionNames';

import { getRequestsApi } from '../../../apis/requests';

function getPendingRequestHistoryDetailsRequest() {
  return {
    type: GET_PENDING_REQUEST_HISTORY_DETAILS_API_REQUEST,
    payload: true,
  };
}

function getPendingRequestHistoryDetailsSuccess(data, params) {
  return {
    type: GET_PENDING_REQUEST_HISTORY_DETAILS_API_SUCCESS,
    payload: data,
    params,
  };
}

function getPendingRequestHistoryDetailsError(err) {
  return {
    type: GET_PENDING_REQUEST_HISTORY_DETAILS_API_ERROR,
    payload: err,
  };
}

export function getPendingRequestHistoryDetailsAction(params) {
  // const response = getPayloadResponse();
  return (dispatch, getState) => {
    const state = getState();
    // console.log(state);
    dispatch(getPendingRequestHistoryDetailsRequest());
    return getRequestsApi
      .getPendingRequestHistoryDetails(state, params)
      .then(data => {
        dispatch(getPendingRequestHistoryDetailsSuccess(data, params));
        return data;
      })
      .catch(error => {
        dispatch(getPendingRequestHistoryDetailsError(error));
        throw error;
        // return response;
      });
  };
}

export default { getPendingRequestHistoryDetailsAction };
